import { prefix, screenSM, screenMD, screenMaxMD } from '../helpers/constants';

const stickyHeaders = (_stickiesList) => {

  const $cache = {
    'filtersContainer': $(`.${ prefix }-m-schedule-filter`),
    'globalNavDesktop': $(`#cs-header`),
    'globalNavMobile': $('#header-mobile'),
    'wrapperContent': $('#schedule-wrapper-content')
  },
  mqPhoneUp = window.matchMedia(`(max-width: ${ screenSM }px)`),
  mqPhoneCustom = window.matchMedia(`(min-width: ${ screenMD }px) and (max-width: ${ screenMaxMD }px)`);

  let globalNavDistance = 0,
    stickies = (_stickiesList.length > 0) ? _stickiesList : [];

  /**
   *  Update generic bar with current time
   * @Param {Object}. Element with date
   */
  const displayCurrentTime = (_source) => {
    let $wrapper = $cache.filtersContainer.find('.current-time-display'),
      template = `<p class="date">${_source.text()}</p>`;
      $wrapper.empty();
      $wrapper.html(template);
  };

  /**
   *  Get global Navigator height according viewport
   */
  const calculateGlobalNavHeight = () => {
    let distance = (mqPhoneUp.matches) ? $cache.globalNavMobile.outerHeight() : $cache.globalNavDesktop.outerHeight();
    return distance;
  };

  globalNavDistance = calculateGlobalNavHeight();

  /**
   *  Update global timebar on scrolling
   */
  const scrolling = () => {
    let headerHeight = $cache.filtersContainer.outerHeight();

    if (mqPhoneUp.matches) {
      headerHeight = ($cache.filtersContainer.outerHeight() + globalNavDistance);
    }

    let elementOffset = 0,
      elementHeight = 0,
      currentTop = 0;

    $.each(stickies, function(_i, _elm) {

      currentTop = $(_elm).offset().top;
      elementOffset = (currentTop - headerHeight);
      if (!isNaN(elementOffset)) {
        elementHeight = elementOffset + $(`.${$(_elm).attr('id')}`).outerHeight() + ($(_elm).outerHeight() * 2);

        if (window.pageYOffset >= elementOffset && window.pageYOffset <= elementHeight) {
          if (!$(_elm).hasClass('current-date')) {
            $(_elm).addClass('current-date');
            displayCurrentTime($(_elm));
          }
          return false;
        } else {
          $cache.wrapperContent.find(`.${prefix}-m-schedule-time`).removeClass('current-date');
        }
      }
    });
  };

  //  listeners
  mqPhoneUp.addListener = () => {
    globalNavDistance = calculateGlobalNavHeight();
  };

  //  listeners
  mqPhoneCustom.addListener = () => {
    globalNavDistance = calculateGlobalNavHeight();
  };

  if (stickies.length > 0) {
    let $currentBarTime = {};

    $.each(stickies, function(_i, _bar) {
      $currentBarTime = $(_bar);
      if (_i > 0) {
        $currentBarTime.addClass('no-first');
      } else {
        displayCurrentTime($currentBarTime);
      }
      $currentBarTime.attr('data-offset', $currentBarTime.offset().top);
    });

    $(window).on('scroll touchmove', function() {
      scrolling();
    });
  } else {
    $cache.filtersContainer.find('.current-time-display').empty();
  }

};

export default stickyHeaders;
