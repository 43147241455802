import { prefix, sliderTime } from '../helpers/constants';
import SliderDot from './progressBar';

export default () => {

  // Slider Elements
  const $heroHomepage = $(`.${ prefix }-m-heros-homepage`).find('.sliders');

  const $cardSlider   = $(`.${ prefix }-m-card.is-slider`).parent();

  const $ranchSlider   = $(`.${ prefix }-m-ranch-slider`);


  // Slider Options
  const defaultOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: sliderTime,
    dots: true,
    pauseOnFocus: false,
    pauseOnHover: false
  };


  // Slider Calls
  $heroHomepage.slick( defaultOptions );
  $cardSlider.slick( defaultOptions );

  if (!$('.page-ranch-hero').length) {
    // Slider Dots
    const sliderDot = new SliderDot();

    $('.slick-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {

      const active = $( slick.$dots[0] ).find('li').eq( nextSlide ).data('dot'),
            prev   = $( slick.$dots[0] ).find('li').eq( currentSlide ).data('dot');

      sliderDot.animateDot( prev, active );

    });
  } else {
    defaultOptions.prevArrow = '<span role="button" class="carousel-control-prev-icon"></span>';
    defaultOptions.nextArrow = '<span role="button" class="carousel-control-next-icon"></span>';
    defaultOptions.arrows = true;
    $ranchSlider.slick(defaultOptions);
  }
}
