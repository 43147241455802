export default () => {
  var userAgent, ieReg, ie;
  userAgent = window.navigator.userAgent;
  ieReg = /msie|Trident.*rv[ :]*11\./gi;
  ie = ieReg.test(userAgent);

  if(ie) {
    $('.hero .images').each(function () {
      var $container = $(this),
          imgUrl = $container.find('img:first-child').prop('src');
      if (imgUrl) {
        $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('custom-object-fit');
      }

      $('.hero .images img').each(function() {
        $(this).addClass('featured-image');
      });
    });
  }
}
