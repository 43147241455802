class Favorites {
  constructor() {
    try {
      this.listItems = (window.localStorage.getItem('favorites') !== null) ? this.parseStringifyObject(window.localStorage.getItem('favorites')) : [];
    } catch (_err) {
      this.listItems = [];
    }

    $.notify.addStyle('eventAddedToFavorites', {
      html: '<div class="event-notification"><span class="red-start"></span><span data-notify-text/></div>',
      position: 'top center'
    });

    $.notify.addStyle('eventRemovedFromFavorites', {
      html: '<div class="event-notification"><span class="red-start"></span><span data-notify-text/></div>',
      position: 'top center'
    });
  }

  /**
   *  Modify events list
   *  @param {Object}. Event information
   */
  updateItems (_event) {
    if(!this.itemExists(_event.eventID)) {
      this.listItems.unshift(_event);
      $.notify('EVENT ADDED TO FAVOURITES', {
        autoHide: true,
        autoHideDelay: 3000,
        className: 'event-added-notification',
        clickToHide: true,
        style: 'eventAddedToFavorites',
      });
    } else {
      this.removeItem(_event.eventID);
      $.notify('EVENT REMOVED FROM FAVOURITES', {
        autoHide: true,
        autoHideDelay: 3000,
        className: 'event-added-notification',
        clickToHide: true,
        style: 'eventRemovedFromFavorites',
      });
    }
    this.saveOnLocalStorage();
  }

  /**
   *  Add Event to your list
   *  @param {Object}. Event information
   */
  addItem (_event) {
    this.listItems.unshift(_event);
  }

  /**
   *  Get current items saved
   */
  getItemsSaved () {
    return this.listItems;
  }

  /**
   *  Check if the event selected already exists.
   *  @param {Integer}. Event Id
   */
  itemExists (_eventId) {
    let obj =  _.find(this.listItems, function (_obj) {
      return parseInt(_obj.eventID) === parseInt(_eventId);
    });
    return obj !== undefined;
  }

  /**
   *  Remove event of the list
   *  @param {Integer}. Event Id
   */
  removeItem (_eventId) {
    let index = this.listItems.findIndex(function(_obj) {
      return _obj.eventID === _eventId;
    });
    this.listItems.splice(index,1);
  }

  /**
   *  Keep localStorage updated
   */
  saveOnLocalStorage () {
    window.localStorage.setItem('favorites', this.stringifyObject(this.getItemsSaved()));
  }

  stringifyObject(_obj)  {
    let str = '';
    try {
      str = encodeURIComponent(JSON.prune(_obj));
    } catch(_err) {
      console.error(_err); // eslint-disable-line
    }
    return str;
  }

  parseStringifyObject(_obj) {
    let str = '';
    try {
      str = JSON.parse(JSON.parse(JSON.prune(decodeURIComponent(_obj))));
    } catch(_err) {
      console.error(_err); // eslint-disable-line
    }
    return str;
  }
}

export default Favorites;
