import { prefix } from '../helpers/constants';

export default () => {

  const $calendarEventSection = $(`.${prefix}-m-calendar-events`),
        $monthSelect = $calendarEventSection.find('.month-select'),
        $navTabs = $calendarEventSection.find('.nav-tabs li a'),
        $calendarCardEvent = $(`.${prefix}-m-calendar-events`).find('.event-card');

  $calendarCardEvent.matchHeight();

  $monthSelect.on('change', function() {
    const targetClass = $(this).val().replace('#', '');
    $(`.${targetClass}`).trigger('click');
  });

  $navTabs.on('shown.bs.tab', function (e) {
    $calendarCardEvent.matchHeight();
    $(`.${prefix}-m-calendar-events .month-select option[value="${$(e.target).attr('href')}"]`).attr('selected', 'selected');
  });
}
