import { sliderTime } from '../helpers/constants';

class SliderDot {


  constructor() {

    this.dots = {};

    this.addDots();

  }

  addDots() {

    const $sliderDot = $('.slick-slider').find('.slick-dots').find('li').find('button');

    $sliderDot.each((index, value) => {

      const dot = new ProgressBar.Circle(value, {
        strokeWidth: 10,
        easing: 'easeInOut',
        duration: sliderTime + 1000,
        color: '#FFFFFF',
        trailWidth: 1,
        svgStyle: null
      });

      this.dots[`dot-${ index }`] = dot;

      const $li = $(value).parent();

      $li.attr('data-dot', `dot-${ index }`);

      if( $li.hasClass('slick-active') ) {
        dot.animate(1);
      }

    });

  }

  animateDot(lastIndex, newIndex) {

    this.dots[lastIndex].set(0);
    this.dots[newIndex].animate(1);

  }

}

export default SliderDot;
