/**
 * Check if the user is on a mobile device or not
 */
export function isMobile() {
  let check = false;
  const screen_size = $(window).width();
  if (screen_size <= 1199) {
    check = true;
  }
  return check;
}

/**
 * Create an object with all the parameters passed and return it
 */
export function getParams() {
    let query = (window.location.search || '?').substr(1),
        map   = {};
    query.replace(/([^&=]+)=?([^&]*)(?:&+|$)/g, (match, key, value) => {
      map[key] = value || true;
    });
    return map;
}

/**
 * Encoding string that contains single quotes
 * Reference: http://mdn.beonex.com/en/JavaScript/Reference/Global_Objects/encodeURIComponent.html
 */
export function fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, escape);
}
