export default () => {

  const $iframeClass = $('.iframe-video');
  const $videoSrc = [];

  /* Get iframe src */
  $($iframeClass).map(function() {
    $videoSrc.push($(this).attr('src'));
  });

  /* Assign empty url value to the iframe src attribute when
  modal hide, which stop the video playing */
  $('.modal-video').on('hide.bs.modal', function() {
    $($iframeClass).each(function() {
      $(this).attr('src', '');
    });
  });

  /* Assign the initially stored url back to the iframe src
  attribute when modal is displayed again */
  $('.modal-video').on('show.bs.modal', function() {
    $($iframeClass).each(function(i) {
      $(this).attr('src', $videoSrc[i]);
    });
  });

  /* Add class to change bg-color of the modal */
  $('.modal-card').on('show.bs.modal', function() {
    setTimeout(function() {
      $('.modal-backdrop').addClass('modal-backdrop-gradient');
    }, 50);
  });
}
