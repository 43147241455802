import { prefix } from '../helpers/constants';

export default () => {

  const $showtimes      = $(`.${prefix}-m-showtimes`),
        $toggleTimes    = $showtimes.find('.js-toggle-times'),
        $panels         = $showtimes.find('.panel').find('.collapse');

  $showtimes.on('click', '.js-toggle-times:not(.active)', function(e) {

    e.preventDefault();
    $panels.collapse('show');
    $toggleTimes.addClass('active');

  });

  $showtimes.on('click', '.js-toggle-times.active', function(e) {

    e.preventDefault();
    $panels.collapse('hide');
    $toggleTimes.removeClass('active');

  });

}
