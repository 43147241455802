export const prefix = 'cs';
export const sliderTime = 10000;
export const heightNav = 110;
export const marginDesktopSticky = 123;
export const marginMobileSticky = 69;
export const marginTabletSticky = 18;

//google-maps
export const latitude = 51.036892;
export const longitude = -114.053873;
export const zoom = 18;
export const mapType = 'CS';
export const offsetTopFilterContainer = 50;

//queries
export const screenSM = 768;
export const screenMD = 769;
export const screenMaxMD = 1199;
