/**
 * All the keys, API endpoints, etc go here.
 */

const config = {
  api: '/scripts/es6/api/testSchedule.json'
};

export const api = config.api;

export default config;
