// Import of custom modules
import Favorites from './modules/favorites/favorites';
import filterMap from './modules/map/filter-map';
import filters from './modules/filters';
import hero from './modules/hero';
import initMap from './modules/map/initMap';
import modal from './modules/modal';
import scriptOld from './modules/script-old';
import showtimes from './modules/showtimes';
import calendar from './modules/calendar';

// Import of vendor modules
import slick from './vendors/slick';
import matchHeight from './vendors/matchHeight';

$(document).ready(() => {

  // Calls of custom modules
  modal();

  hero();

  scriptOld();

  showtimes();

  // Calls of vendors modules
  slick();


  const FavoritesItems = new Favorites();
  filters(FavoritesItems);

  if (typeof google !== 'undefined') {
    const csMap = initMap(FavoritesItems);
    filterMap(csMap);
  }

  matchHeight();

  calendar();
  
  if ($('.node-class-1982 ')) {
    $('.node-class-1982 .module-wrapper .container').prepend(`<p class="landing_page-text">
    The Western Oasisis an exciting destination at the Calgary Stampede. We work year-round to bring Stampede visitors a one-of-a-kind Western experience through art, music,crafts, artisans,a live kitchen, andhome décor all centered on the theme of our Western Canadian lifestyle.
    </br>While you’re soaking in the art and culture of the Oasis, enjoy a glass of wine from the bar and a splendid meal prepared in our open kitchen by Stampede chefs. What an elegant and tranquil way to enjoy the Stampede and the Oasis.
    </p>`)  
  }
  if ($('.node-class-1983 ')) {
    $('.node-class-1983 .module-wrapper .container').prepend(`<p class="landing_page-text">
    Western Oasis hosts one of the best artisan and craft displays anywhere in Canada. More than 150 artisans compete for prizes and exhibit in categories including quilting, cake decorating, wood working, drawing & sketching, fiber arts, and much more.
    </br>Don’t miss the Maker Market in Hall A where you’ll find wood creations, leather work, jewelry, home décor, and a whole lot more all for sale by independent artisan vendors. Check back as the vendors change throughout the 10 days of stampede.
    </p>`)  
  }
  if ($('.node-class-1984 ')) {
    $('.node-class-1984 .module-wrapper .container').prepend(`<p class="landing_page-text">
    Professional, new and emerging artists and photographers come together in the Western Oasis to exhibit and sell their works created in a wide variety of mediums and forms to appeal to all interests—and budgets.
    </p>`)  
  }

  

});
