import { prefix, marginDesktopSticky, marginTabletSticky, screenSM, screenMD, screenMaxMD } from '../helpers/constants';
import { api } from '../helpers/config';
import stickyTimebars from '../vendors/stickyTimebars';

Array.prototype.insert = function(index, item) {
  this.splice(index, 0, item);
};

export default (_favoritesClass) => {
  
  if ($(`.${ prefix }-p-schedule`).length) {

    const cache = {
      'isToday': false,
      'buttons': {
        'filterDayBtn': $('#filter-days'),
        'filterEventsBtn': $('#filter-events'),
        'filterVenuesBtn': $('#filter-venues'),
        'filterSettings': $('#filter-settings'),
        'filterAllExBtn': $('#all-exhibits'),
        'filterAllEvBtn': $('#all-events'),
        'scheduleBtnMob': $('#schedule-events'),
        'allExhibitsBtnMob': $('#exhibits-day'),
        'downloadScheduleDayBtn': $('#download-event-day'),
        'filterHapenning': $('#filter-hapenning'),
        'filterHapenningMobile': $('#filter-hapenning-mobile'),
        'favorites': $('.toggle-favorites-events'),
        'bottomFilter': $('#bottom-filters')
      },
      'modals': {
        'modalDays': $('#modal-days'),
        'modalEvents': $('#modal-events'),
        'modalVenues': $('#modal-venues'),
        'modalHappening': $('#modal-happening'),
        'modalFavorites': $('#modal-events-favorites')
      },
      'mainContainer': $('#header-filters'),
      'wrapperContent': $('#schedule-wrapper-content'),
      'overlay': $('#modal-overlay'),
      'service': $('#header-filters').data('api'),
      'date': $('#filter-days'),
      'filterContainer': $('.filter-container'),
      'wrapperHappening': $(`.${ prefix }-m-happening`),
      'filtersContainer': $(`.${ prefix }-m-schedule-filter`),
      'mobileFavoritesBtnWrapper': $('.mobile-favorites-events')
    };

    // The 'Information is not available...' message is removed until the new section is ready.
    const erroMessage = '',
      MAX_EVENTS_SHOW = 2,
      MAX_LENGTH_STRING = 120,
      MODAL_DAYS_ID = 4,
      MODAL_EVENTS_ID = 1,
      MODAL_FAVORITES = 3,
      MODAL_VENUES_ID = 2,
      FILTERS_TOP = cache.filtersContainer.offset().top,
      mqPhoneUp = window.matchMedia(`(max-width: ${ screenSM }px)`),
      mqPhoneCustom = window.matchMedia(`(min-width: ${ screenMD }px) and (max-width: ${ screenMaxMD }px)`);

    var modal = {},
      nodeCollections = {},
      $allVenues = cache.modals.modalVenues.find('ul .event-item'),
      $allCategories = cache.modals.modalEvents.find('ul .event-item');

    let userSelection = {};

    const scrollToTime = () => {

      if (cache.isToday) {

        //offset -6 Hours * 60 min
        let timezoneCalgary = parseInt(moment().utcOffset(-360).format('H'));
        //let currentDayTime = moment().format('MMM DD h');

        var scrollTo = $('.scrollTo');

        if (scrollTo.length === 0) {
          var notEvent = true;

          var forwardEvent = timezoneCalgary
          while (notEvent && forwardEvent < 24) {
            scrollTo = $('#time-event-' + forwardEvent);
            if (scrollTo.length > 0) {
              notEvent = false;
              break;
            }

            forwardEvent++;
          }

          var behind = timezoneCalgary
          while (notEvent && behind > 0) {
            scrollTo = $('#time-event-' + behind);
            if (scrollTo.length > 0) {
              notEvent = false;
              break;
            }

            behind--;
          }
        }

        if (scrollTo.length > 0) {
          //  Prevent auto scroll in browser
          if ('scrollRestoration' in window.history) {
            window.scrollTo(0,0);
            window.history.scrollRestoration = 'manual';
          }

           $('html, body').stop(true, true).animate({
            scrollTop: scrollTo.offset().top - 220
          }, 'slow', () => {
            scrollTo.focus();
            if (!scrollTo.is(':focus')) {
              scrollTo.attr('tabindex', '-1');
              scrollTo.focus();
            }
          });
        }

      }

    };

    const calculateStringMaxLength = (str, extraCut = 0) => {
      if (str.length > MAX_LENGTH_STRING) {
        let maximumLength = MAX_LENGTH_STRING - extraCut;
        return str.substr(0, maximumLength) + '...';
      } else {
        return str;
      }
    }

    const upcomingEvents = (event) => {
      const artistID = event.node.field_artist_id_hidden;
      const url = (cache.service) ? cache.service : api;
      let tempStr = '';
      let eventReferenceTime = moment(`${event.node.field_event_date3} ${event.node.start_time.toLowerCase()}`)
      tempStr += `<div id="coming-upcoming-event-${event.collectionId}" class="upcoming-events">`;

      $.ajax({ url: `${url}/api/events_artist/${artistID}`, method: 'GET' }).then((_result) => {

        let listItemsTemplate = '';
        let countShowEvents = 0;
        let visibleList = [];
        let invisibleList = [];

        $.each(_result.nodes, function(upcomingEventIndex, upcomingEvent) {
          const regex = /\d{2}:\d{2}$/gm;
          const str = upcomingEvent.node['finish time'];
          let m = regex.exec(str);
          let startTime = moment(upcomingEvent.node['start time'].toLowerCase(), 'h:mm A');
          let finishTime = m !== '' ? moment(m, 'HH:mm:ss').format('h:mm a') : 'ALL DAY';
          let upcomingDateTime = moment(`${upcomingEvent.node['upcoming date']} ${startTime.format('h:mm A').toLowerCase()}`);

          if (eventReferenceTime.isBefore(upcomingDateTime)) {

            if (countShowEvents <= MAX_EVENTS_SHOW) {
              visibleList.push(`<li>${upcomingEvent.node['upcoming date']}, <strong>${startTime.format('h:mm A').toLowerCase()} - ${finishTime.toLowerCase()}</strong></li>`);
            } else {
              invisibleList.push(`<li>${upcomingEvent.node['upcoming date']}, <strong>${startTime.format('h:mm A').toLowerCase()} - ${finishTime.toLowerCase()}</strong></li>`);
            }

            countShowEvents++;
          }
        });

        if (!_.isEmpty(visibleList)) {
          listItemsTemplate += `<h6>UPCOMING TIMES</h6>`;
          listItemsTemplate += `<ul>${visibleList.join('')}</ul>`;
          listItemsTemplate += `<ul id="show-more-${event.collectionId}" class="show-more-events">${invisibleList.join('')}</ul>`;
          listItemsTemplate += `<a class="link view-all-events" target="_blank" href="${event.node.event_url}" data-id="${event.collectionId}" aria-expanded="false">See all times</a>`;
        } else {
          listItemsTemplate += '';
        }

        $(`#coming-upcoming-event-${event.collectionId}`).append(listItemsTemplate);
      });

      tempStr += '</div>';

      return tempStr;
    }

    const stringifyObject = (_obj) => {
      let str = '';
      try {
        str = encodeURIComponent(JSON.prune(_obj));
      } catch(_err) {
        console.error(_err); // eslint-disable-line
      }
      return str;
    }

    const parseStringifyObject = (_obj) => {
      let str = '';
      try {
        str = JSON.parse(JSON.parse(JSON.prune(decodeURIComponent(_obj))));
      } catch(_err) {
        console.error(_err); // eslint-disable-line
      }
      return str;
    }

    /**
     * Create activities
     * @param  { Object } - _activities. Activities availables per datetime
     * @return {string} - Return template
     */
    const happeningCardTemplate = (_activities) => `
    ${ _activities.map(function callback(currentValue) {
      let items = '';
      for (var event of currentValue[1]) {
        items += `
        ${ event.isAllDay ? `
        <div class="cs-c-column col-xs-12 col-sm-6 col-md-4">
        <div class="item cs-c-happening-card">
            <a href="#" class="add-favorites" aria-label="Add to Favorites" data-id="${event.collectionId}" data-event="${stringifyObject(event)}" }'>
              <svg class="favorites">
                <use xlink:href="#icon-favorites"></use>
              </svg>
            </a>
            <a href="${event.node.event_url}" data-toggle="modal" class="item ${prefix}-c-happening-card">
              <div class="content">
                <div class="copy">
                  <div class="top-section">
                    <span class="head-cat">${event.category}</span>
                  </div>
                  <div class="middle-section">
                    <h5>${event.artist}</h5>
                  </div>
                  <div class="bottom-section">
                    <span class="venue-term">${event.title}</span>
                    <h6>${event.startHour} - ${event.endHour}</h6>
                  </div>
                </div>
              </div>
            </a>
            <div class="icon-container">
              <a class="icon-download" data-event="${stringifyObject(event)}"></a>
            </div>
            </div>
          </div>
        ` : `` }
        `;
      }
      return items;
    }).join('') }`;

    /**
     * Create a macro template
     * @param  { Object } - _schedule. Events availables per datetime
     * @return {string} - Return template
     */
    // let timeNow = parseInt(moment().format('HH'));
    // let dayNow = moment().format('MMM DD');
    let currentDayTime = moment().utcOffset(-360).format('MMM DD H');
    //currentDayTime = currentDayTime.substring(0, currentDayTime.length - 1);
    const renderTemplate = (_schedule) =>
    `
      ${ _schedule.activities.map((info) => `
      <div id="time-event-${ info[0].toString() } ${info[1]['0'].node.hourDayTimeOnNow !== currentDayTime ? '' : 'moveToTop'}" class="${ prefix }-m-schedule-time time-event-schedule">
        ${info[1]['0'].node.hourDayTimeOnNow !== currentDayTime ? `
          <p class="time-bar followMeBar">${ moment(info[0].toString(), 'HH').format('h:mm a') }</p>
        ` : `<p class="time-bar followMeBar scrollTo">EVENTS ON NOW</p>
        </div>
        `}
      </div>
      </div>
      <div class="module-wrapper time-event-${ info[0].toString() } ${info[1]['0'].node.hourDayTime !== currentDayTime ? '' : 'moveToTop'}">
        ${ info[1].map(function(event) {
            if (event.isFeature)
            {
              return `
              <div class="container">
                <div class="row row-banner-schedule">
                  <div class="cs-m-banners-schedule is-schedule">
                    <div class="no-padding-right col-xs-12 col-sm-5 col-md-7">
                      <div class="image cs-match">
                        <div class="visible-md visible-lg" style="background-image: url( ${ event.image } )">
                        </div>
                        <div class="visible-xs visible-sm" style="background-image: url( ${ event.image } )">
                        </div>
                      </div>
                    </div>
                    <div class="no-padding-left col-xs-12 col-sm-7 col-md-5">
                      <div class="bg-gradient content cs-match">
                        <ul class="headline list-inline">
                          <li>${ event.category }</li>
                          <li>${ event.title }</li>
                        </ul>
                        <h3>${ calculateStringMaxLength(event.modalCard.copy, 50) !== '' ? calculateStringMaxLength(event.modalCard.copy, 50) : event.artist }</h2>
                        <h4>${ event.startHour } - ${ event.endHour }</h3>
                        <div class="links">
                          <a ${ event.modalCard ? `data-toggle="modal" data-target="#modal-card-${ event.modalCard.id }"` : `` } class="link btn btn-primary">Details</a>
                          <a target="_blank" href="/stampede/maps/park?venue_id=${ event.venue_id }" class="link btn btn-primary">View Map</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            ${ event.modalCard.isEnabled ? `
            <div class="${ prefix }-c-column">
              <div id="modal-card-${ event.modalCard.id }" class="modal fade modal-card" role="dialog">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <a role="button" class="btn-close-modal" data-dismiss="modal"><i class="icon-close-modal"></i></a>
                    </div>
                    <div class="modal-body">
                      <div class="${ prefix }-m-card is-modal is-schedule">
                        <div class="image" role="image" style="background-image: url(${ event.image });"></div>
                        <div class="image map-point" role="image" style="background-image: url(${ event.image });"></div>
                        <div class="content">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="item ${ prefix }-c-happening-card modal-bottom ">
                                <a class="happening-link" href="${ event.node.event_url }"></a>
                                <div class="top-section">
                                  <span class="head-cat">${ event.category }</span>
                                </div>
                                <div class="middle-section">
                                  <h5>${ event.artist }</h5>
                                </div>
                                <span class="venue-term">${ event.title }</span>
                                <div class="time-download">
                                  <h6>${ event.startHour } - ${ event.endHour }</h6>
                                  <div class="icon-container">
                                    <a class="icon-download" data-event="${stringifyObject(event)}"></a>
                                  </div>
                                </div>
                                ${ event.caption ? `<p class="modal-caption">${calculateStringMaxLength(event.caption)}</p>` : `` }
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="links">
                                <a target="_blank" href="${event.node.event_url}" class="link btn btn-primary">Details</a>
                                <a target="_blank" href="/stampede/maps/park?venue_id=${event.venue_id}" class="link btn btn-primary">View Map</a>
                              </div>
                              <div class="item ${ prefix }-c-happening-card modal-bottom">
                                ${upcomingEvents(event)}
                              </div>
                              ${ event.modalCard.hasSponsorImage ? `
                              <h6 class="sponsored-tag">SPONSORED BY</h6>
                              <div class="sponsors">
                                <img src="${ event.modalCard.sponsor.image }" alt="">
                              </div>
                              ` : ``}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                    ` : ``}
              `;
            }
          }).join('')}
          <div class="container">
             <div class="row ${ (info[1].length > 1) ? 'row-centered' : 'row-centered-schedule' }">
             ${ info[1].map(event => `
             ${ !event.isFeature ? `
              <div class="${ prefix }-c-column col-xs-12 col-sm-6 col-md-4">
                  <div class="item ${ prefix }-c-happening-card">
                    <a href="#" class="add-favorites" aria-label="Add to Favorites" data-id="${event.collectionId}" data-event="${stringifyObject(event)}" }'>
                      <svg class="favorites">
                        <use xlink:href="#icon-favorites"></use>
                      </svg>
                    </a>
                    <a href="#" class="content" ${ event.modalCard ? `data-toggle="modal" data-target="#modal-card-${ event.modalCard.id }"` : `` }">
                      <div class="${ prefix }-background-event" style="background-image: url(${event.image});"></div>
                      <div class="copy ${ prefix }-card-match">
                        <div class="top-section">
                          <span class="head-cat">${ event.category }</span>
                        </div>
                        <div class="middle-section">
                          <h5>${ event.artist }</h5>
                        </div>
                      </div>
                    </a>
                    <div class="bottom-section">
                      <span class="venue-term">${ event.title }</span>
                      <div class="time-download">
                        <h6>${ event.startHour } - ${ event.endHour }</h6>
                        <div class="icon-container">
                          <a class="icon-download" data-event="${stringifyObject(event)}"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                    ${ event.modalCard.isEnabled ? `
                    <div id="modal-card-${ event.modalCard.id }" class="modal fade modal-card" role="dialog">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <a role="button" class="btn-close-modal" data-dismiss="modal"><i class="icon-close-modal"></i></a>
                          </div>
                          <div class="modal-body">
                            <div class="${ prefix }-m-card is-modal is-schedule">
                              <div class="image" role="image" style="background-image: url(${ event.image });"></div>
                              <div class="image map-point" role="image" style="background-image: url(${ event.image });"></div>
                              <div class="content">
                                <div class="row">
                                  <div class="col-lg-6">
                                    <div class="item ${ prefix }-c-happening-card modal-bottom">
                                      <a class="happening-link" href="${ event.node.event_url }"></a>
                                      <div class="top-section">
                                        <span class="head-cat">${ event.category }</span>
                                      </div>
                                      <div class="middle-section">
                                        <h5>${ event.artist }</h5>
                                      </div>
                                      <span class="venue-term">${ event.title }</span>
                                      <div class="time-download">
                                        <h6>${ event.startHour } - ${ event.endHour }</h6>
                                        <div class="icon-container">
                                          <a class="icon-download" data-event="${stringifyObject(event)}"></a>
                                        </div>
                                      </div>
                                      ${ event.caption ? `<p class="modal-caption">${calculateStringMaxLength(event.caption)}</p>` : `` }
                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <div class="links">
                                      <a target="_blank" href="${event.node.event_url}" class="link btn btn-primary">Details</a>
                                      <a target="_blank" href="/stampede/maps/park?venue_id=${event.venue_id}" class="link btn btn-primary">View Map</a>
                                    </div>
                                    <div class="item ${ prefix }-c-happening-card modal-bottom">
                                      ${upcomingEvents(event)}
                                    </div>
                                    ${event.modalCard.hasSponsorImage ? `
                                    <h6 class="sponsored-tag">SPONSORED BY</h6>
                                    <div class="sponsors">
                                      <img src="${ event.modalCard.sponsor.image }" alt="">
                                    </div>
                                    ` : ``}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>` : ``}
              </div>
              `:`` }
              `).join('')}
             </div>
        </div>
      </div>
      `).join('') }
      <div class="${ prefix }-m-spacer"></div>
      `;

    const groupEventsByHour = (_events) => {
      let groups = Array();

      for (var i = 0; i < _events.length; i++) {
        let groupName = parseInt(_events[i].hour);
        if (!groups[groupName]) {
          groups[groupName] = [];
        }
        groups[groupName].push(_events[i].event);

        for (let key in groups) {
          groups[key];
        }
      }

      let parseObjectToArray = Object.keys(groups).map(function(e) {
        return [Number(e), groups[e]];
      });

      return parseObjectToArray;
    }

    const groupEventsByDay = (_events) => {
      let groups = Array();

      for (var i = 0; i < _events.length; i++) {
        let groupName = _events[i].day;
        if (!groups[groupName]) {
          groups[groupName] = [];
        }
        groups[groupName].push(_events[i].event);

        for (let key in groups) {
          groups[key];
        }
      }

      let parseObjectToArray = Object.keys(groups).map(function(e) {
        return [e, groups[e]];
      });

      return parseObjectToArray;
    }

    /**
     * get Data from ajax result and render all info in html markup
     * @param {array} - _info ajax data result
     * @return {string} - return string with templates
     */
    const getData = (_info) => {

      let collectionByHours = [];
      let collectionForMainRender = {};
      let collectionForMainRenderByHours = [];

      if (_info.hasOwnProperty('nodes') && Array.isArray(_info.nodes) && _info.nodes.length > 0) {

        let dataFiltered = _info.nodes;

        let resultNodes = [];

        let counter = 1;

        for (let filter of dataFiltered) {
          const regex = /\d{2}:\d{2}:\d{2}$/gm;
          const str = filter.node.field_event_finish_hour;
          let m = regex.exec(str);
          filter.venue_id = filter.node.venue_id;
          filter.collectionId = filter.node['Field collection item ID'].replace(',', '');
          filter.eventID = filter.collectionId;
          filter.image = filter.node.field_artist_image_hidden;
          filter.caption = filter.node.field_artist_body_hidden;
          filter.startHour = filter.node.field_event_date3_4;
          filter.endHour = m !== '' ? moment(m, 'HH:mm:ss').format('h:mm a') : 'ALL DAY';
          filter.title = filter.node.field_event_location_venue;

          if (filter.node.field_event_name === '') {
            filter.artist = filter.node.field_artist_hidden;
          } else {
            filter.artist = filter.node.field_event_name;
          }

          filter.category = filter.node.field_venue_hidden;
          filter.isFeature = filter.node.field_is_featured === '1' || filter.node.field_is_featured === 1 ? true : false;
          filter.isAllDay = filter.node.is_all_day === '1' || filter.node.is_all_day === 1 ? true : false;
          filter.link = {};
          filter.link.title = filter.node.field_event_name;
          filter.link.caption = filter.caption;
          filter.modalCard = {};
          filter.modalCard.isEnabled = true;
          filter.modalCard.caption = filter.caption;
          filter.modalCard.id = counter++;
          filter.modalCard.copy = filter.caption;
          let endTime = filter.endHour !== null && filter.endHour !== undefined ? `- ${ filter.endHour }` : ``;
          filter.modalCard.datetime = `${ filter.node.field_event_date3_2 } ${ filter.node.field_event_date3_3 } ${ filter.node.field_event_date3_1 }, ${ filter.startHour } ${ endTime }`;
          filter.modalCard.sponsor = {};
          filter.modalCard.sponsor.image = filter.node.field_artist_logo_hidden;
          filter.modalCard.viewMap = 'https://www.calgarystampede.com/stampede/maps/park';
          filter.modalCard.hasSponsorImage = filter.node.field_artist_logo_hidden !== undefined && filter.node.field_artist_logo_hidden !== '' ? true : false;
          filter.modalCard.moreTimes = filter.node.event_url;
          filter.node.hourDayTime = filter.node.field_event_date3 + ' ' + parseInt(filter.node.field_event_date3_4);
          
          let twentyFourHourTime = moment(filter.node.field_event_date3_4, ['h:mm A']).format('HH');
          filter.node.hourDayTimeTwentyHour = twentyFourHourTime;
          filter.node.hourDayTimeOnNow = filter.node.field_event_date3 + ' ' + twentyFourHourTime;

          collectionByHours.push({ hour: filter.node.field_event_date3_4 !== '' ? moment(filter.node.field_event_date3_4, 'h:mm a').format('HH') : '', event: filter });
          resultNodes.push(filter);
          if (!filter.isAllDay) {
            collectionForMainRenderByHours.push({ hour: filter.node.field_event_date3_4 !== '' ? moment(filter.node.field_event_date3_4, 'h:mm a').format('HH') : '', event: filter });
          }
        }

        nodeCollections.activities = groupEventsByHour(collectionByHours);
        collectionForMainRender.activities = groupEventsByHour(collectionForMainRenderByHours);

        $.extend(cache, { dataStoraged:  nodeCollections.activities });
      }

      cache.overlay.fadeOut('fast');

      //Sort events in each group so they go by start hour 
      collectionForMainRender.activities.forEach(function(item) {
        item[1].sort((a, b) => moment(a.startHour, 'HH:mm A').valueOf() - moment(b.startHour, 'HH:mm A').valueOf());
      });

      return renderTemplate(collectionForMainRender);
    };

    /**
     * Make ajax call
     */
    const applyFilter = () => {
      let url = (cache.service) ? cache.service : api,
        params = '',
        regExs = {
          'date': /{\$date}/i,
          'event': /{\$event}/i,
        },
        config = {
          method: 'GET',
          beforeSend: () => {
            cache.wrapperContent.empty();
            
            if (!mqPhoneUp.matches && cache.filtersContainer.hasClass('sticky')) {
              cache.filtersContainer.removeClass('sticky');
              cache.wrapperContent.css({'padding-top': 0});
            }

            $('html, body').stop(true,true).animate({
              scrollTop: 0
            });
          }
        };

      if (userSelection.hasOwnProperty('days')) {
        params = userSelection.days.api;
      }

      if (userSelection.hasOwnProperty('cta')) {
        params = userSelection.cta.api.replace(regExs.date, userSelection.days.code);
      } else {

        if (userSelection.hasOwnProperty('events')) {
          params = userSelection.events.api.replace(regExs.date, userSelection.days.code);
        }

        if (userSelection.hasOwnProperty('venues')) {
          params = userSelection.venues.api.replace(regExs.date, userSelection.days.code);
          params = params.replace(regExs.event, userSelection.events.code);
        }
      }

      let urlRequest = url + params;

      urlRequest = urlRequest.replace('`', '');

      $.extend(config, { url: `${urlRequest}` });

      $.ajax(config).then((_result) => {
        cache.wrapperContent.empty();
        
        if ((Array.isArray(_result.nodes) && _result.nodes.length !== 0)) {
          cache.wrapperContent.append(getData(_result)).promise().done(() => {
            cache.wrapperHappening.html(happeningCardTemplate(nodeCollections.activities));
            $('body').trigger('event-loaded');
            $(`.${prefix}-card-match`).matchHeight();
            $(`.${prefix}-match`).matchHeight();
            toggleEventsHappening();
          });
        } else {
          cache.wrapperContent.append(`<h4 class="ajax-message">No result</h4>`);
          cache.filtersContainer.find('.current-time-display').css('z-index', -1);
        }

      }, () => {
        cache.wrapperContent.append(`<h4 class="ajax-message">${ erroMessage }</h4>`);
      });
    };

    const toggleEventsHappening = () => {
          
      let happeningContainer = $('#modal-happening .cs-m-happening').children();

      if (happeningContainer.length > 0) {
        $('#happening-filter-text').text('VIEW EVENTS HAPPENING ALL DAY');
        cache.buttons.filterHapenning.css('pointer-events', 'all');
        $('#happening-filter-arrow').css('visibility', 'visible');
        $('.wrapper-nav-wrap').removeClass('noAllDayEvents')
      } else {
        $('.wrapper-nav-wrap').addClass('noAllDayEvents')
        $('#happening-filter-text').text('NO ALL-DAY EVENTS TODAY');
        cache.buttons.filterHapenning.css('pointer-events', 'none');
        $('#happening-filter-arrow').css('visibility', 'hidden');
      }
    }

    /**
     * Update data each time user select a different item
     * @param {obj} - _info item selected and type
     */
    const updateUserSelection = (_info) => {
      let selection = {},
        filterData = _info.api.split('/');

      switch (_info.type) {
        case 'events':
          removeProperty('cta');
          selection = { 'events': { 'api': _info.api, 'code': filterData[3].toString() } };
          break;
        case 'venues':
          removeProperty('cta');
          selection = { 'venues': { 'api': _info.api, 'code': filterData[4].toString() } };
          break;
        case 'days':
          removeProperty('cta');
          selection = { 'days': { 'api': _info.api, 'code': filterData[2].toString() } };
          if (selection.days.code === moment().format('YYYY-MM-DD')) {
            cache.isToday = true;
          } else {
            cache.isToday = false;
          }
          break;

        default:
          selection = { 'cta': { 'api': _info.api, 'code': _info.code } };
          break;
      }
      $.extend(userSelection, selection);
    };

    /**
     * Empty userSelection local object
     * @param {String} - _property. Property to be removed.
     */
    const removeProperty = (_property) => {
      delete userSelection[_property];
    };

    /**
     * Close all modals
     */
    const resetModals = () => {
      cache.buttons.filterDayBtn.attr('aria-expanded', false);
      cache.mainContainer.find('.modal-dialog').removeClass('modal-open').addClass('modal-close');
    };

    /**
     * Show/Hide modal selected by the user
     */
    const animateModal = () => {
      if (modal.hasClass('modal-open')) {
         modal.removeClass('modal-open');
         $('body').removeClass('prevent-scroll');
         //cache.overlay.fadeOut('fast'); //  keep it for future uses
      } else {
        // $('body').addClass('prevent-scroll');
        modal.removeClass('modal-close').addClass('modal-open');
        //cache.overlay.fadeIn('fast'); //  keep it for future uses
      }
    };

    /**
    * Remove null, 0, blank, false, undefined and NaN values from an array
    */
    const cleanArray = (array)  => {
      let index = -1,
          arr_length = array ? array.length : 0,
          resIndex = -1,
          result = [];

      while (++index < arr_length) {
        let value = array[index];

        if (value) {
          result[++resIndex] = value;
        }
      }

      return result;
    }

    const applyCacheFilter = (eventsByHours) => {
      cache.overlay.fadeOut('fast');

      let categoriesFilter = [];
      let allCategories = $($allCategories);
      let applyFilterCategory = false;
      let venuesFilter = [];
      let allVenues = $($allVenues);
      let applyFilterVenues = false;

      allCategories.each(function() {
        let category = $(this);
        if (category.hasClass('all-items') && !category.hasClass('item-active')) {
          applyFilterCategory = true;
        } else if (applyFilterCategory && category.hasClass('item-active')) {
          categoriesFilter.push(parseInt(category.data('id')));
        }
      });

      if (applyFilterCategory && !_.isEmpty(categoriesFilter)) {
        eventsByHours = eventsByHours.map(function(groupByHour) {
          let groupResult = _.filter(groupByHour[1], function(event) {
            if (categoriesFilter.includes(parseInt(event.node.Category))) {
              return event;
            }
          });

          if (!_.isEmpty(groupResult)) {
            return [groupByHour[0], groupResult];
          }
        });

        eventsByHours = cleanArray(eventsByHours);
      }

      allVenues.each(function() {
        let venue = $(this);
        if (venue.hasClass('all-items') && !venue.hasClass('item-active')) {
          applyFilterVenues = true;
        } else if (applyFilterVenues && venue.hasClass('item-active')) {
          venuesFilter.push(parseInt(venue.data('id')));
        }
      });

      if (applyFilterVenues && !_.isEmpty(venuesFilter)) {
        eventsByHours = eventsByHours.map(function(groupByHour) {
          let groupResult = _.filter(groupByHour[1], function(event) {
            if (venuesFilter.includes(parseInt(event.node.venue_id))) {
              return event;
            }
          });

          if (!_.isEmpty(groupResult)) {
            return [groupByHour[0], groupResult];
          }
        });

        eventsByHours = cleanArray(eventsByHours);
      }

      //  hide the timebar
      if (eventsByHours.length === 0) {
        cache.filtersContainer.find('.current-time-display').empty();
      }

      return renderTemplate({activities: eventsByHours});
    }

    /**
     * Set option selected by the user for the button clicked
     * @param {obj} - _event Catch event triggered
     * @param {obj} - _btn Button clicked by the user
     * @param {Boolean} - _truncate Indicate if the text should be reduced.
     */
    const setOption = (_event, _btn, _truncate = false) => {

      let $currentEl = $(_event.currentTarget),
        $currentType = $currentEl.closest('ul'),
        $currentParent = $currentEl.parent(),
        optionText = $currentEl.text(),
        unselecteAction = false,
        lastItemSelected = null,
        hasDataSelected = false,
        config = {};

        switch($currentType.data('type')) {
          case 'events':
            if (!$currentEl.hasClass('all-items')) {

              $.each($allCategories, function(_i, _e) {
                let element = $(_e);
                if (element.hasClass('all-items') && element.hasClass('item-active')) {
                  element.removeClass('item-active');
                }

                if (element.hasClass('item-active') &&
                 0 !== _i &&
                $currentEl.parent().index() !== _i) {
                  hasDataSelected = true;
                  lastItemSelected = element;
                }
              });

              if ($currentEl.hasClass('item-active')) {
                $currentEl.removeClass('item-active');
                unselecteAction = true;

                if (!hasDataSelected) {
                  $allVenues.removeClass('inactive');
                  $allCategories.removeClass('item-active');
                  $($allVenues[0]).addClass('item-active');

                  let firstItem = $($allCategories[0]);
                  firstItem.addClass('item-active');
                  lastItemSelected = firstItem;
                }
              } else {
                $currentEl.addClass('item-active');
              }
            }
          break;
          case 'venues':
            if (!$currentEl.hasClass('all-items')) {
              $($allVenues[0]).removeClass('item-active');

              $.each($allVenues, function(_i, _e) {
                let element = $(_e);

                if (element.hasClass('all-items') && element.hasClass('item-active')) {
                  element.removeClass('item-active');
                }

                if (element.hasClass('item-active') &&
                 0 !== _i &&
                $currentEl.parent().index() !== _i) {
                  hasDataSelected = true;
                  lastItemSelected = element;
                }
              });

              if ($currentEl.hasClass('item-active')) {
                $currentEl.removeClass('item-active');
                unselecteAction = true;

                if (!hasDataSelected) {
                  let firstItem = $($allVenues[0]);
                  firstItem.addClass('item-active');
                  lastItemSelected = firstItem;
                }
              } else {
                $currentEl.addClass('item-active');
              }
            }
          break;
          default:
            cache.modals.modalDays.find('ul .event-item').removeClass('item-active');
            $currentEl.addClass('item-active');
          break;
        }


        if ($currentType.data('type') === 'days' || $currentEl.hasClass('all-items')) {
          $.each($allVenues, function(_i, _e) {
            let element = $(_e);
            if (!_i) {
              element.addClass('item-active');
            } else {
              element.removeClass('inactive item-active');
            }
          });

          $.each($allCategories, function(_i, _e) {
            let element = $(_e);
            if (!_i) {
              element.addClass('item-active');
            } else {
              element.removeClass('inactive item-active');
            }
          });

          config = { 'type': $currentType.data('type'), api: $currentParent.data('api') };
          updateUserSelection(config);
          applyFilter();
        } else {
          cache.wrapperContent.html(applyCacheFilter(cache.dataStoraged));
          cache.wrapperHappening.html(happeningCardTemplate(cache.dataStoraged));
          $('body').trigger('event-loaded');
          $(`.${prefix}-card-match`).matchHeight();
          $(`.${prefix}-match`).matchHeight();
        }

      if (!$currentParent.is(':first-child') && ($currentType.data('type') !== 'days')) {
        if (lastItemSelected && unselecteAction) {
          optionText = lastItemSelected.text().substr(0, 7) + '...';
        } else {
          optionText = (_truncate && (optionText.length > 7)) ? optionText.substr(0, 7) + '...' : optionText;
        }
      }

      // Scroll to top
      if (!mqPhoneUp.matches && cache.filtersContainer.hasClass('sticky')) {
        cache.filtersContainer.removeClass('sticky');
        cache.wrapperContent.css({'padding-top': 0});
      }

      $('html, body').stop(true,true).animate({
        scrollTop: 0
      });

      _btn.html(`${optionText}<span class="icon-arrow"></span>`);
    };

    /**
     *  Allow the user to select only the venues related with the event selected
     * @params {Jquery Object} _elem. Event item selected by the user
     */
    const setVenuesAvailables = (_elem) => {
      let $currentFilter = $(_elem),
        itemId = parseInt($currentFilter.data('id'));

      $allVenues.removeClass('inactive'); //reset venues

      if (itemId > 0) {
        let filterValues = [];
        let applyFilter = false;

        if (!$currentFilter.hasClass('all-items')) {
          applyFilter = true;
        }

        if (applyFilter) {

          filterValues.push(parseInt($currentFilter.data('id')));

          $.each($allCategories, function(_i, _e) {
            let category = $(_e);
            if (!category.hasClass('all-items')
            && category.hasClass('item-active')) {
              filterValues.push(parseInt(category.data('id')));
            }
          });

          let $venue = {};

          $.each($allVenues, function(_i, _e) {
            $venue = $(_e);
            for (var index = 0; index < filterValues.length; index++) {
              let categoryId = filterValues[index];
              if (!$venue.hasClass('all-items')) {
                if (!$venue.data('categories').includes(categoryId)) {
                  $venue.addClass('inactive');
                } else {
                  $venue.removeClass('inactive');
                }
              }
            }
          });
        }
      }
    }

    /**
     * Set the modal type
     * @param {Number} - _modalType. 1= Events modal, 2= Venues Modal
     */
    const setModal = (_modalType) => {
      if (_modalType > 0) {
        switch (_modalType) {
          //events
          case 1:
            modal = cache.modals.modalEvents;
            break;

            //venues
          case 2:
            modal = cache.modals.modalVenues;
            break;

          case 3:
            //favorites
            modal = cache.modals.modalFavorites
            break;

            //days
          default:
            modal = cache.modals.modalDays;
            break;
        }
        animateModal();
      }
    };

    /**
     * Format the current date
     * @param {Date} - _date. Date to be formatted
     */
    const formatDate = (_date) => {
      let month = _date.getMonth() + 1,
        day = _date.getDate(),
        year = _date.getFullYear(),
        format = '';

      month = (month < 10) ? '0' + month.toString() : month.toString();
      day = (day < 10) ? '0' + day.toString() : day.toString();
      format = `${year}-${month}-${day}`;
      return format;
    };

    const downloadScheduleList = [
      { index: 0, url: '/downloads' },
      { index: 1, url: '/downloads' },
      { index: 2, url: '/downloads' },
      { index: 3, url: '/downloads' },
      { index: 4, url: '/downloads' },
      { index: 5, url: '/downloads' },
      { index: 6, url: '/downloads' },
      { index: 7, url: '/downloads' },
      { index: 8, url: '/downloads' },
      { index: 9, url: '/downloads' },
      { index: 10, url: '/downloads' }
    ];

    /**
     * Check if the current datetime exists into the list of availables dates
     */
    const stampideCurrentDate = () => {
      let todayDate = new Date(),
        todayFormat = formatDate(todayDate),
        modalDays = cache.modals.modalDays.find('ul'),
        modalEvents = cache.modals.modalEvents.find('ul'),
        modalVenues = cache.modals.modalVenues.find('ul'),
        daysFilterItems = modalDays.find('li'),
        eventsFilterItems = modalEvents.find('li'),
        venuesFilterItems = modalVenues.find('li'),
        dateText = '',
        splitData = [];

      for (let x = 0; x < daysFilterItems.length; x++) {
        splitData = $(daysFilterItems[x]).data('api').split('/');
        if (todayFormat === splitData[2].toString()) {
          modalDays.find('.item-active').removeClass('item-active');
          $(daysFilterItems[x]).addClass('item-active');
          dateText = $(daysFilterItems[x]).text();

          let indexDownloadSchedule = $(daysFilterItems[x]).data('schedule');

          for (let downloadEvent in downloadScheduleList) {
            if (downloadScheduleList[downloadEvent].index === indexDownloadSchedule) {
              cache.buttons.downloadScheduleDayBtn.attr('href', downloadScheduleList[downloadEvent].url);
            }
          }
          updateUserSelection({ type: 'days', api: $(daysFilterItems[x]).data('api'), code: splitData[2] });
          updateUserSelection({ type: 'events', api: $(eventsFilterItems[0]).data('api') });
          updateUserSelection({ type: 'venues', api: $(venuesFilterItems[0]).data('api') });
          applyFilter();
          cache.buttons.filterDayBtn.html(`${dateText}<span class="icon-arrow"></span>`);
        }
      }

      if (!cache.isToday) {
        let indexDownloadSchedule = $(daysFilterItems[0]).data('schedule');
        for (let downloadEvent in downloadScheduleList) {
          if (downloadScheduleList[downloadEvent].index === indexDownloadSchedule) {
            cache.buttons.downloadScheduleDayBtn.attr('href', downloadScheduleList[downloadEvent].url);
          }
        }
        let temp = $(daysFilterItems[0]).data('api').split('/');
        updateUserSelection({ type: 'days', api: $(daysFilterItems[0]).data('api'), code: temp[2] });
        updateUserSelection({ type: 'events', api: $(eventsFilterItems[0]).data('api') });
        updateUserSelection({ type: 'venues', api: $(venuesFilterItems[0]).data('api') });
        applyFilter();
      }

    };

    /**
     * Prevent scrolling when the modals (days, filters) are expanded
     */
    const preventBodyScroll = () => {
      // $('body').toggleClass('prevent-scroll');
    };

    /**
     * Open filters configuration updating state
     */
    const openFilters = () => {
      cache.buttons.filterSettings.attr('aria-expanded', true).text('Close filter ').append('<span class="icon-close"></span>');

      //  Events / Venues
      cache.buttons.filterEventsBtn.attr('aria-expanded', true).removeClass('no-selected').addClass('active-tab-events');
      cache.buttons.filterVenuesBtn.attr('aria-expanded', false).addClass('no-selected').removeClass('active-tab-venues');
      cache.filterContainer.fadeIn('fast');
      preventBodyScroll();
    };

    /**
     * Close filters configuration and set default state
     */
    const closeFilters = () => {
      cache.buttons.filterSettings.attr('aria-expanded', false).text('Filter events ').append('<span class="icon-settings"></span>');

      //  Events / Venues
      cache.buttons.filterEventsBtn.attr('aria-expanded', false).removeClass('no-selected');
      cache.buttons.filterVenuesBtn.attr('aria-expanded', false).addClass('no-selected');
      cache.filterContainer.fadeOut('fast');
      preventBodyScroll();
    };

    /**
     * Set the filter buttons(events/venues) behavior
     * @param {Object} _config Set of properties to apply over the button clicked
     */
    const setActiveFilterList = (_config) => {
      if (typeof _config === 'object') {
        resetModals();
        $(_config.enabledBtn).attr('aria-expanded', true).removeClass('no-selected').addClass(_config.activeClass);
        $(_config.disabledBtn).attr('aria-expanded', false).addClass('no-selected').removeClass(_config.previousClass);
        setModal(_config.modalID);
      }
    };

    /**
     * Set default state over the All Day events Modal
     */
    const resetAllDayEventsModal = () => {
      cache.buttons.filterHapenning.attr('aria-expanded', false);
      cache.modals.modalHappening.find('.modal-dialog').addClass('modal-close').removeClass('modal-open');
    };

    const renderFavoriteEvents = (_elements) => `
      <div class="${ prefix }-m-happening items" style="display: flex;">
      ${ _elements.map((event) =>
        `<div class="group-action">
          <a href="#" ${ event.modalCard ? `data-toggle="modal" data-target="#modal-card-${ event.modalCard.id }"` : `` } class="item cs-c-happening-card">
            <div class="container">
              <div class="row row-banner-schedule">
                    <div class="content">
                      <div class="copy">
                        <div class="middle-section">
                          <h5>${ event.artist }</h5>
                        </div>
                        <div class="bottom-section">
                          <span class="venue-term">${ event.title }</span>
                          <h6>${ event.startHour } - ${ event.endHour }</h6>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div class="set-icons">
                    <a target="_blank" href="${ encodeURI(`mailto:?subject=${event.artist}&body=${event.caption}\n${ event.modalCard.datetime }\n${ event.modalCard.moreTimes }`) }" class="icon">
                      <span class="icon-email"></span>
                      EMAIL EVENT
                    </a>
                    <a class="icon icon-download" data-event="${stringifyObject(event)}">
                      <span class="icon-download-logo"></span>
                      ADD TO CALENDAR
                    </a>
                    <a class="icon" target="_blank" href="/stampede/maps/park?venue_id=${event.venue_id}">
                      <span class="icon-map"></span>
                      MAP VIEW
                    </a>
                  </div>
                  <a href="#" class="add-favorites isModal" aria-label="Add to Favorites" data-id="${event.collectionId}" data-event="${stringifyObject(event)}" }'></a>
                </div>`).join('')}
              </div>
            </div>
        </div>`;

    const renderNoSavedEvents = () => `
    <div class="${ prefix }-no-events">
      <div class="no-events-container">
        <span class="red-start"></span>
        <h5>YOU HAVE NO SAVED EVENTS</h5>
        <p>You can save an event by selecting the star on the Stampede Schedule</p>
        <a href="/stampede/schedule" class="link btn btn-primary">VIEW SCHEDULE</a>
      </div>
    </div>
    `;

    const renderGroups = (_events) => {
      let template = '';
      let collectionBydays = [];

      $.each(_events, function(_i, _elm) {
        collectionBydays.push({ day: _elm.node.field_event_date3 !== '' ? moment(_elm.node.field_event_date3, 'MMMM DD').format('ll') : '', event: _elm });
      });

      collectionBydays = groupEventsByDay(collectionBydays);

      template += `
            <div class="content-wrapper">
            ${collectionBydays.reverse().map((_elm) => `
              <h4 class="group-title-event">${moment(_elm[0], 'MMMM DD').format('dddd, MMMM DD')}</h4>
              ${renderFavoriteEvents(_elm[1])}
            `).join('')}
            </div>
          `;

      if (typeof collectionBydays[0] !== 'undefined' && collectionBydays[0] !== null) {
        cache.modals.modalFavorites.removeClass('no-events-found');
        cache.modals.modalFavorites.html(template);
      } else {
        cache.modals.modalFavorites.addClass('no-events-found');
        cache.modals.modalFavorites.html(renderNoSavedEvents());
      }
    }

    const setModalEventFavorites = () => {
      let _favorites = _favoritesClass.getItemsSaved();
      renderGroups(_favorites);
      $('body').trigger('event-loaded');
    }

    /**
     * Set sticky behavior on filters according media-querie pre-defined
     */
    const setStickyOnFilters = () => {
      if (mqPhoneUp.matches) {
        // cache.wrapperContent.css({'padding-top': `${marginMobileSticky}px`});
        cache.filtersContainer.addClass('sticky');
        $(window).off('scroll');
      } else {
        let distance = (mqPhoneCustom.matches) ? marginTabletSticky : marginDesktopSticky;
        $(window).on('scroll touchmove', function () {
          if ($(this).scrollTop() > FILTERS_TOP) {
            cache.filtersContainer.addClass('sticky');
            $('#modal-happening').addClass('sticky');
            // cache.wrapperContent.css({'padding-top': `${distance}px`});
            console.log(distance);
          } else {
            cache.filtersContainer.removeClass('sticky');
            $('#modal-happening').removeClass('sticky');
            cache.wrapperContent.css({'padding-top': 0});
          }
        });
      }
    };

    /**
     * Initialize event listeners
     */
    const initListeners = () => {

      //  Open/close days modal
      cache.buttons.filterDayBtn.on('click', function(event) {
        if (cache.buttons.filterSettings.attr('aria-expanded') === 'true') {
          resetModals();
          closeFilters();
        }

        let $btn = $(this),
          isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;

        $btn.attr('aria-expanded', isExpanded);
        setModal(MODAL_DAYS_ID);
        resetAllDayEventsModal();
        event.preventDefault();

      });

      //  Open/close filter events modals
      cache.buttons.filterSettings.on('click', function(event) {
        let btn = $(this);

        if (btn.attr('aria-expanded') === 'true') {
          closeFilters();
        } else {
          resetModals();
          openFilters();
        }

        setModal(MODAL_EVENTS_ID);
        resetAllDayEventsModal();
        event.preventDefault();
      });

      cache.buttons.filterEventsBtn.on('click', function(event) {
        let $btn = $(this);

        if ($btn.attr('aria-expanded') !== 'true') {
          setActiveFilterList({
            'enabledBtn': $btn,
            'activeClass': 'active-tab-events',
            'previousClass': 'active-tab-venues',
            'disabledBtn': cache.buttons.filterVenuesBtn,
            'modalID': MODAL_EVENTS_ID
          });
        }
        event.preventDefault();
      });

      cache.buttons.filterVenuesBtn.on('click', function(event) {
        let $btn = $(this);

        if ($btn.attr('aria-expanded') !== 'true') {
          setActiveFilterList({
            'enabledBtn': $btn,
            'activeClass': 'active-tab-venues',
            'previousClass': 'active-tab-events',
            'disabledBtn': cache.buttons.filterEventsBtn,
            'modalID': MODAL_VENUES_ID
          });
        }
        event.preventDefault();
      });

      cache.buttons.filterAllExBtn.on('click', function(event) {
        if (cache.buttons.filterAllEvBtn.hasClass('hidden')) {
          $(this).addClass('hidden');
          updateUserSelection({ type: 'cta', api: $(this).data('api'), code: 'AEX' });
          applyFilter();
          cache.buttons.filterAllEvBtn.removeClass('hidden');
        }
        event.preventDefault();
      });

      cache.buttons.filterAllEvBtn.on('click', function(event) {
        if (cache.buttons.filterAllExBtn.hasClass('hidden')) {
          $(this).addClass('hidden');
          updateUserSelection({ type: 'cta', api: $(this).data('api'), code: 'AEV' });
          applyFilter();
          cache.buttons.filterAllExBtn.removeClass('hidden');
        }
        event.preventDefault();
      });

      cache.buttons.scheduleBtnMob.on('click', function(event) {
        cache.buttons.allExhibitsBtnMob.toggleClass('filtered');
        updateUserSelection({ type: 'cta', api: $(this).data('api'), code: 'SBM' });
        applyFilter();
        $(this).toggleClass('filtered');
        event.preventDefault();
      });


      cache.buttons.allExhibitsBtnMob.on('click', function(event) {
        cache.buttons.scheduleBtnMob.toggleClass('filtered');
        updateUserSelection({ type: 'cta', api: $(this).data('api'), code: 'AEM' });
        applyFilter();
        $(this).toggleClass('filtered');
        event.preventDefault();
      });

      $('#filter-days, #filter-settings').on('click', function() {
        $('.modal-happening').removeClass('heightFull');
        $('.modal-dialog').removeClass('heightFull');
      });

      cache.buttons.filterHapenning.on('click', function(event) {

        let $btn = $(this),
        
        isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;
        if (cache.buttons.filterSettings.attr('aria-expanded') === 'true') {
          closeFilters();
          setModal(MODAL_EVENTS_ID);
        }
        if (!$('#schedule-wrapper-content .ajax-message')) {
          $('.modal-happening').toggleClass('heightFull');
          $('.modal-dialog').toggleClass('heightFull');
        }

        if (cache.buttons.filterDayBtn.attr('aria-expanded') === 'true') {
          cache.buttons.filterDayBtn.attr('aria-expanded', 'false');
          setModal(MODAL_DAYS_ID);
        }

        $btn.attr('aria-expanded', isExpanded);

        let modalHappening = cache.modals.modalHappening.find('.modal-dialog');

        if (isExpanded) {
          modalHappening.fadeIn('fast');
          modalHappening.addClass('modal-open').removeClass('modal-close');
          // $('body').addClass('prevent-scroll');
        } else {
          modalHappening.fadeOut('fast');
          modalHappening.removeClass('modal-open').addClass('modal-close');
          // $('body').removeClass('prevent-scroll');
          resetAllDayEventsModal();
        }
        event.preventDefault();
      });

      cache.buttons.filterHapenningMobile.on('click', function(event) {
          
        $('#modal-happening .cs-m-schedule-time').toggleClass('hide');

        let $btn = $(this);

        if (cache.buttons.filterSettings.attr('aria-expanded') === 'true') {
          closeFilters();
          setModal(MODAL_EVENTS_ID);
        }

        if (cache.buttons.filterDayBtn.attr('aria-expanded') === 'true') {
          cache.buttons.filterDayBtn.attr('aria-expanded', 'false');
          setModal(MODAL_DAYS_ID);
        }

        if ($btn.attr('aria-expanded') === 'true') {
          $btn.attr('aria-expanded', 'false');
          $btn.parent('#bottom-filters').css('bottom', '0px');
          cache.wrapperHappening.css('display', 'none');
        } else {
          $btn.attr('aria-expanded', 'true');
          $btn.parent('#bottom-filters').css('bottom', '180px');
          cache.wrapperHappening.css('display', 'block');
        }
        event.preventDefault();
      });


      if ($(window).width() < 798) {
        let boundEvents = $._data($('#filter-hapenning-mobile')[0], 'events');
        boundEvents = boundEvents.click;
        for (let i = 0; i < boundEvents.length; i++) {
          let event = boundEvents[i];
          $('#bottom-filters').bind(event.type, event.handler);
        }
        $('#filter-hapenning-mobile').off('click');
      }
        
      cache.modals.modalDays.find('ul').on('click', '.event-item', function(ev) {
        let indexDownloadSchedule = $(this).data('schedule');
        for (let downloadEvent in downloadScheduleList) {
          if (downloadScheduleList[downloadEvent].index === indexDownloadSchedule) {
            cache.buttons.downloadScheduleDayBtn.attr('href', downloadScheduleList[downloadEvent].url);
          }
        }
        setOption(ev, cache.buttons.filterDayBtn);
        cache.buttons.filterAllEvBtn.addClass('hidden');
        cache.buttons.filterAllExBtn.removeClass('hidden');
        cache.buttons.filterDayBtn.attr('aria-expanded', 'false');
        animateModal();
        ev.preventDefault();
      });

      cache.modals.modalEvents.find('ul').on('click', '.event-item', function(ev) {
        if (!$(ev.currentTarget).hasClass('item-active')) {
          setVenuesAvailables(ev.currentTarget);
        }

        setOption(ev, cache.buttons.filterEventsBtn, true);
        ev.preventDefault();
      });

      cache.modals.modalVenues.find('ul').on('click', '.event-item', function(ev) {
        if(!$(ev.currentTarget).hasClass('inactive')) {
          setOption(ev, cache.buttons.filterVenuesBtn, true);
        }
        ev.preventDefault();
      });

      cache.overlay.on('click', function(ev) {
        if (cache.buttons.filterDayBtn.attr('aria-expanded') === 'true') {
          cache.buttons.filterDayBtn.attr('aria-expanded', 'false');
          setModal(MODAL_DAYS_ID);
        }

        if (cache.buttons.filterSettings.attr('aria-expanded') === 'true') {
          cache.buttons.filterSettings.attr('aria-expanded', 'false');
          closeFilters();
          setModal(MODAL_EVENTS_ID);
        }
        ev.preventDefault();
      });

      cache.buttons.favorites.on('click', function(ev) {
        let $btn = $(this),
            isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;

        if (typeof cache.favorites !== 'undefined') {
            if (cache.buttons.filterSettings.attr('aria-expanded') === 'true') {
              closeFilters();
              setModal(MODAL_EVENTS_ID);
            }

            if (cache.buttons.filterDayBtn.attr('aria-expanded') === 'true') {
              cache.buttons.filterDayBtn.attr('aria-expanded', 'false');
              setModal(MODAL_DAYS_ID);
            }

            if (isExpanded) {
              setModalEventFavorites();
            }
          }

          if(isExpanded) {
            cache.mobileFavoritesBtnWrapper.addClass('display-triangle');
          } else {
            cache.mobileFavoritesBtnWrapper.removeClass('display-triangle');
          }

          $btn.attr('aria-expanded', isExpanded);

          setModal(MODAL_FAVORITES);
          ev.preventDefault();
        });

        mqPhoneUp.addListener = () => {
          setStickyOnFilters();
        };

        mqPhoneCustom.addListener = () => {
          setStickyOnFilters();
        };

        $('body').on('event-loaded', function() {
          let stickies = $.makeArray(cache.wrapperContent.find(`.${prefix}-m-schedule-time`));

          if (stickies.length > 1) {
            stickyTimebars(stickies);
            setStickyOnFilters();
          } else {
            $(window).off('scroll');
          }

          $('.icon-download').unbind().on('click', function(event) {
            let found =  parseStringifyObject($(this).attr('data-event'));

            if (typeof found !== 'undefined') {
              let cal = icsGen(),
                filename = found.artist;

              cal.addEvent(found.artist,
                found.caption,
                found.title,
                `7/${found.node.field_event_date3_1}/2019 ${found.startHour}`,
                `7/${found.node.field_event_date3_1}/2019 ${found.endHour}`, null);
              cal.download(filename, 'ics',`${cache.service}/sites/all/themes/calgarystampede2017/icalendar.php`);
            }
            event.preventDefault();
          });

          scrollToTime();

          $.extend(cache, {'favorites': $(`.${ prefix }-c-happening-card .add-favorites, .${ prefix }-m-happening .add-favorites`)});

          //  Pre-select favorites from localStorage
          let isEventSaved = false;
          $.each(cache.favorites, function(_i, _elm) {
            isEventSaved = _favoritesClass.itemExists($(_elm).data('id'));
            if (isEventSaved) {
              $(_elm).addClass('favorite');
              cache.buttons.favorites.attr('has-events', 'true');
            } else {
              $(_elm).removeClass('favorite');
            }
          });

          cache.favorites.unbind().on('click', function(ev) {
            let eventCard = $(this);

            $.each(cache.favorites, function(_i, _e) {
              let element = $(_e);

              if (parseInt(element.attr('data-id')) === parseInt(eventCard.attr('data-id'))) {
                element.toggleClass('favorite');
              }
            });

            _favoritesClass.updateItems(parseStringifyObject(eventCard.data('event')));

            let favorites = parseStringifyObject(window.localStorage.getItem('favorites'));

            if (eventCard.hasClass('isModal')) {
              setModalEventFavorites();
            }

            if (favorites !== null && favorites.length !== 0) {
              cache.buttons.favorites.attr('has-events', 'true');
            } else {
              cache.buttons.favorites.attr('has-events', 'false');
            }

            ev.preventDefault();
          });

          //  Modal Events Detailed
          $('.modal-card').on('show.bs.modal', function() {
            preventBodyScroll()
          });

          //  Modal Events Detailed
          $('.modal-card').on('hide.bs.modal', function() {
            preventBodyScroll();
          });

        });
      };

    /**
     * Init local configuration
     */
    const initializeSchedule = () => {
      stampideCurrentDate();
      initListeners();
    };

    initializeSchedule();

    cache.buttons.bottomFilter.on('click', function() {
      cache.modals.modalHappening.toggle().toggleClass('allDayEventsToggle');
      cache.wrapperContent.toggle();
    });

  }
}

$(function() {

  //Mobile all day events
  $('#filter-hapenning-mobile').on('click', function() {
    let bottomFilterText = $('#toggleEventTypeText');
    if (bottomFilterText.text() === 'VIEW ALL DAY EVENTS') {
      bottomFilterText.text('VIEW TIMED EVENTS');
    } else {
      bottomFilterText.text('VIEW ALL DAY EVENTS');
    }
    $('#schedule-wrapper-content').toggleClass('toggleBackground');
    
    $('#bottom-filters').toggleClass('toggleBackground');
  })

  let desktopHap = $('#modal-happening #desktop-happening');
  let mobileHap = $('#modal-happening #mobile-happening');
  
  if ($(window).width() < 798) {
    desktopHap.remove();  
  } else {
    mobileHap.remove();
  }

  if ($(window).width() > 798) {

    let filterWidth = $('#filter-hapenning').width();
    $('#modal-happening .container').width(filterWidth - 28);
    
    $(window).resize(function() {
      filterWidth = $('#filter-hapenning').width();
      $('#modal-happening .container').width(filterWidth - 28);
    })
  }

  //Non-mobile all day events
  // $('#filter-hapenning').on('click', function() {
  //   this.text() == 'VIEW EVENTS HAPPENING ALL DAY' ? this.text('VIEW TIMED EVENTS') : this.text('VIEW EVENTS HAPPENING ALL DAY');
  // });

  $(window).scroll(function(){
    if ($(window).scrollTop() >= 300) {
      $('#header-filters').addClass('sticky');
    }
    else {
      $('#header-filters').removeClass('sticky');
    }
  });

  let timebar = $('.time-bar').innerHTML;
  console.log(timebar);

  if ( timebar === 'EVENTS ON NOW' ) {
    console.log('satisfied');
  }
});