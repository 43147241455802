import { prefix, latitude, longitude, zoom, mapType } from '../../helpers/constants';
import { isMobile } from '../../helpers/utils';

class MapConfig {

  constructor(_favoritesClass) {

    this.cache = {
      'body': $(`body.${ prefix }-p-maps`),
      'container': document.getElementById('map-details'),
      'favoritesBtn': $('.toggle-favorites-events'),
      'header': $('#header-map'),
      'mobileFavoritesBtnWrapper': $('.mobile-favorites-events'),
      'modalFavorites': $('#modal-events-favorites')
    };
    this.currentLocation = {};
    this.map = {};
    this.markers = [];
    this.pinBig = {};
    this.pinSelected = {};
    this.pinUnSelected = {};
    this.todayEvents = null;
    this._favoritesClass = _favoritesClass;
    this.MarkerCluster;
  }

 /**
  * Normalize coords for your mapType
  * @param  { Object } - _coord. latitude and longitude
  * @param  { Number } - _zoom.
  * @return {Object} - Coords updated
  */
  getNormalizedCoord(_coord, _zoom) {
    let y = _coord.y,
        x = _coord.x,
        tileRange = 1 << _zoom;

    if (y < 0 || y >= tileRange) {
        return null;
    }

    if (x < 0 || x >= tileRange) {
        return null;
    }

    return {x: x, y: y};
  }

 /**
  * Set a bound for your map
  * @return {Map} - map properties
  */
  setStrictBound() {
    let bounds = {
      'sw': {
        'lat': 51.0305723,
        'lng': -114.059215
      },
      'ne': {
        'lat': 51.041139,
        'lng': -114.045825
      }
    };
    return new google.maps.LatLngBounds( new google.maps.LatLng(bounds.sw), new google.maps.LatLng(bounds.ne) );
  }

 /**
  * Config your ajax call
  * @return {Promise}
  */
  getAsyncMarkers() {
    let config = {
      'method': 'GET',
      'url': '/sites/all/themes/calgarystampede2017/dist2017/data/venues.json'
    };
    return $.ajax(config);
  }

 /**
  * Get yours google.maps.Markers
  * @return {Array}
  */
  getMarkers() {
    return this.markers;
  }

 /**
  * Set yours google.maps.Markers
  */
  setMarkers(_marker) {
    this.markers.push(_marker);
  }

 /**
  * Define your map type
  */
  setMapType() {
    this.map.mapTypes.set(mapType, new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        let normalizedCoord = this.getNormalizedCoord(coord, zoom);

        if (!normalizedCoord) {
            return null;
        }
        return `/sites/all/themes/calgarystampede2017/dist2017/map-tile/${zoom}/${normalizedCoord.x}/${normalizedCoord.y}.png`;
      },
      tileSize: new google.maps.Size(256, 256),
      maxZoom: 18,
      minZoom: 16,
      radius: 2500,
      name: 'CS - Map 2018'
    }) );
    this.map.setMapTypeId(mapType);
  }

 /**
  * Detect all events located in your map along with your dropdowns
  */
  mapListeners() {
    let _self = this,
        bounds = this.setStrictBound();

    google.maps.event.addDomListener(window, 'resize', function() {
      let center = _self.map.getCenter();

      google.maps.event.trigger(_self.map, 'resize');
      _self.map.setCenter(center);
    });

    //listeners
    google.maps.event.addListener(this.map, 'dragend', function () {
      if (bounds.contains(_self.map.getCenter()) ) {
        return;
      }

      // We're out of bounds - Move the map back within the bounds
      let c = _self.map.getCenter(),
          x = c.lng(),
          y = c.lat(),
          maxX = bounds.getNorthEast().lng(),
          maxY = bounds.getNorthEast().lat(),
          minX = bounds.getSouthWest().lng(),
          minY = bounds.getSouthWest().lat();

      if (x < minX) {x = minX}
      if (x > maxX) {x = maxX}
      if (y < minY) {y = minY}
      if (y > maxY) {y = maxY}

      _self.map.setCenter(new google.maps.LatLng(y, x));
    });

    
    google.maps.event.addListener(this.map, 'zoom_changed', function() {
      if (_self.map.zoom <= 16) {
        let mapMarkers = _self.getMarkers();
        mapMarkers = mapMarkers.filter(marker => marker.visible === true);
        _self.MarkerCluster.markers_ = mapMarkers;
      }
    });
  }


  renderModalInfo(_markerInfo) {
    let wrapper = $('#venue-info');
    wrapper.empty();

    let template = `<div class="desktop-container bg-white" >
      <div class="item-details">
        <div class="close-x"><a href="#">&times;</a></div>
        ${ _markerInfo.venue_id !== null ? '<div class="expand-chevron"><a href="#">v</a></div>' : '' }
        <div class="mobile-wrapper">
          ${ _markerInfo.image !== '' && _markerInfo.image !== undefined  ? `<div class="container-fluid detail-map" style="background: url(${_markerInfo.image}) no-repeat center center;" ></div>`:`<div class="cs-m-spacer"></div><div class="cs-m-spacer"></div>` }
        </div>
        <div class="mobile-wrapper left-align">
          ${ _markerInfo.food !== null ? '<span class="food-category">New Food</span>' : '' }
          <h6>${_markerInfo.name}</h6>
          <h6 style="color:#D32D27;font-size: 15px;">${_markerInfo.subtitle}</h6>
          ${ typeof _markerInfo.description !== 'undefined' && _markerInfo.description !== '' ? `<p class="small">${_markerInfo.description}</p>` : '' }
          <p class="mobile-description">${_markerInfo.shortdescription}${ _markerInfo.food !== null ? '...' : '' }</p>
          <div class="details-and-directions">
            ${$(window).width() < 768 ? `<div class="btn btn-primary directions-button">Directions</div>` : ``}
            ${$(window).width() < 768 && _markerInfo.link.url !== '' ? `<span class="break"></span>` : ``}
            ${ _markerInfo.link['url:'] !== '' && _markerInfo.link['url:'] !== undefined ? `<a href="${_markerInfo.link['url:']}" class="details-button link btn btn-primary">See Details</a>`:``}
          </div>
        </div>
      </div>
    </div>
    ${ _markerInfo.link.logo !== '' && _markerInfo.link.logo !== undefined ? `
      <div class="sponsor-image">
        <p class="small">Presented By</p>
        <img src="${ _markerInfo.link.logo }" alt="${_markerInfo.name}"/>
      </div> `:`` }
    </div>`;

    wrapper.html(template).promise().done(() => {
      wrapper.removeClass('show-events type-event type-food hide-info').addClass('show-info');

      let typeClass = (_markerInfo.food === null) ? 'type-event' : 'type-food';
      wrapper.addClass(typeClass);
      let closeModal = $('.close-x a'),
        _self = this,
        bigIcon = '';

      closeModal.on('click', () => {
        if (_self.pinSelected.hasOwnProperty('id') ) {
          bigIcon = _self.pinSelected.icon.replace('MapPins_70x75', 'Map%20Pin%20Icons_PNG_60X65');
          _self.pinSelected.setIcon(bigIcon);
        }

        if (wrapper.hasClass('show-info')) {
          wrapper.removeClass('show-info').addClass('hide-info');
          wrapper.empty();
        }
        if (wrapper.hasClass('type-food')) {
          wrapper.removeClass('type-food');
          wrapper.empty();
        }

      });
    });
    
    let _self = this;

    $('.directions-button').on('click', function() {
      _self.getDirections(_markerInfo);
    })
  }

  groupEventsByHour(_events) {
    let groups = Array();

    for (var i = 0; i < _events.length; i++) {
      let groupName = parseInt(_events[i].hour);
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(_events[i].event);

      for (let key in groups) {
        groups[key];
      }
    }

    let parseObjectToArray = Object.keys(groups).map(function(e) {
      return [Number(e), groups[e]];
    });

    return parseObjectToArray;
  }

  getData(_info) {

    let collectionByHours = [];
    let areEventsSaved = false;

    if (_info.hasOwnProperty('nodes') && Array.isArray(_info.nodes) && _info.nodes.length > 0) {

      let dataFiltered = _info.nodes;
      let resultNodes = [];
      let counter = 1;

      for (let filter of dataFiltered) {
        const regex = /\d{2}:\d{2}:\d{2}$/gm;
        const str = filter.node.field_event_finish_hour;
        let m = regex.exec(str);
        filter.venue_id = filter.node.venue_id;
        filter.collectionId = filter.node['Field collection item ID'].replace(',', '');
        filter.eventID = filter.collectionId;
        filter.image = filter.node.field_artist_image_hidden;
        filter.caption = filter.node.field_artist_body_hidden;
        filter.startHour = filter.node.field_event_date3_4;
        filter.endHour = m !== '' ? moment(m, 'HH:mm:ss').format('h:mm a') : 'ALL DAY';
        filter.title = filter.node.field_event_location_venue;

        if (filter.node.field_event_name === '') {
          filter.artist = filter.node.field_artist_hidden;
        } else {
          filter.artist = filter.node.field_event_name;
        }

        filter.category = filter.node.field_venue_hidden;
        filter.isFeature = filter.node.field_is_featured === '1' || filter.node.field_is_featured === 1 ? true : false;
        filter.isAllDay = filter.node.is_all_day === '1' || filter.node.is_all_day === 1 ? true : false;
        filter.link = {};
        filter.link.title = filter.node.field_event_name;
        filter.link.caption = filter.caption;
        filter.modalCard = {};
        filter.modalCard.isEnabled = true;
        filter.modalCard.caption = filter.caption;
        filter.modalCard.id = counter++;
        filter.modalCard.copy = filter.caption;
        let endTime = filter.endHour !== null && filter.endHour !== undefined ? `- ${ filter.endHour }` : ``;
        filter.modalCard.datetime = `${ filter.node.field_event_date3_2 } ${ filter.node.field_event_date3_3 } ${ filter.node.field_event_date3_1 }, ${ filter.startHour } ${ endTime }`;
        filter.modalCard.sponsor = {};
        filter.modalCard.sponsor.image = filter.node.field_artist_logo_hidden;
        filter.modalCard.viewMap = `${window.location.origin}/stampede/maps/park`;
        filter.modalCard.hasSponsorImage = filter.node.field_artist_logo_hidden !== undefined && filter.node.field_artist_logo_hidden !== '' ? true : false;
        filter.modalCard.moreTimes = filter.node.event_url;
        collectionByHours.push({ hour: filter.node.field_event_date3_4 !== '' ? moment(filter.node.field_event_date3_4, 'h:mm a').format('HH') : '', event: filter });
        resultNodes.push(filter);

        if (this._favoritesClass.itemExists(filter.collectionId)) {
          areEventsSaved = true;
        }
      }
    }

    this.todayEvents = collectionByHours;

    if (areEventsSaved) {
      this.cache.favoritesBtn.attr('has-events', true);
    }
  }

  parseStringifyObject(_obj) {
    let str = '';
    try {
      str = JSON.parse(JSON.parse(JSON.prune(decodeURIComponent(_obj))));
    } catch(_err) {
      console.error(_err); // eslint-disable-line
    }
    return str;
  }

  stringifyObject(_obj) {
    let str = '';
    try {
      str = encodeURIComponent(JSON.prune(_obj));
    } catch(_err) {
      console.error(_err); // eslint-disable-line
    }
    return str;
  }

  renderEvents(_elements) {
    return `<div class="${ prefix }-m-happening items" style="display: flex;">
    ${ _elements.map((event) =>
      `<div class="group-action">
        <div class="item">
          <div class="content">
            <div class="copy">
              <div class="middle-section">
                <h4>${event.category} ${event.title ? '  /  ' + event.title : ''}</h4>
                <h5>${ event.artist }</h5>
              </div>
              <div class="bottom-section">
                <div class="time-download">
                  <h6>${ event.startHour } - ${ event.endHour }</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="details-wrap">
            <a href="${event.node.event_url}">DETAILS</a>
          </div>
        </div>
        <a href="#" class="add-favorites ${this._favoritesClass.itemExists(event.collectionId) ? 'favorite' : ''}" aria-label="Add to Favorites" data-id="${event.collectionId}" data-event="${this.stringifyObject(event)}" }'>
          <svg class="favorites">
            <use xlink:href="#icon-favorites"></use>
          </svg>
        </a>
      </div>`).join('')}
    </div>`;
  }

  renderModalEvents(_marker) {
    let wrapper = $('#venue-info');
    let actualHour = parseInt(moment().format('HH'));
    wrapper.empty();
    let counterMaxShowHappeningNow = 0;
    let counterMaxShowUpcoming = 0;
    let eventsRelated = [];
    const MAX_SHOW_EVENTS = 3;

    if (this.todayEvents !== null) {
      eventsRelated = this.groupEventsByHour(this.todayEvents.reverse().filter(function(_item) {
        if (parseInt(_item.event.venue_id) === _marker.venue_id &&
          actualHour <= parseInt(_item.hour)) {

          if (parseInt(_item.hour) === actualHour) {
            counterMaxShowHappeningNow++;

            if (counterMaxShowHappeningNow <= MAX_SHOW_EVENTS) {
              return _item;
            }
          } else {
            counterMaxShowUpcoming++;

            if (counterMaxShowUpcoming <= MAX_SHOW_EVENTS) {
              return _item;
            }
          }
        }
      }));
    }

    let upComingIsRendered = true;
    let happeningNowIsRendered = true;

    let templateBase = '';

    if ( $(window).width() > 768 || !_.isEmpty(eventsRelated)) {
      templateBase = `<div class="desktop-container bg-white">
        <div class="item-details event-venue">
          ${ _marker.venue_id !== null ? '<div class="expand-chevron"><a href="#">v</a></div>' : '' }
          <div class="close-x"><a href="#" data-venue-id="${_marker.id}">&times;</a></div>
          <div class="mobile-wrapper">
            ${ _marker.image !== '' && _marker.image !== undefined  ? `<div class="container-fluid detail-map" style="background: url(${_marker.image}) no-repeat center center;" ></div>`:`<div class="cs-m-spacer"></div><div class="cs-m-spacer"></div>` }
          </div>
          <div class="mobile-wrapper left-align">
            ${ _marker.food !== null ? '<span class="food-category">Featured Food</span>' : '' }
            <h6>${_marker.name}</h6>
            ${ _marker.description !== null || _marker.description !== '' ? `<p class="small">${_marker.description}</p>` : '' }
            <div class="details-and-directions">
              ${$(window).width() < 768 ? `<div class="link btn btn-primary directions-button">Directions</div>` : ``}
              ${$(window).width() < 768 ? `<span class="break"></span>` : ``}
              ${ _marker.link['url:'] !== '' && _marker.link['url:'] !== undefined ? `<a href="${_marker.link['url:']}" class="details-button link btn btn-primary">See Details</a>`:``}
            </div>
          </div>
        </div>
        ${ _marker.link.logo !== '' && _marker.link.logo !== undefined ? `
        <div class="sponsor-image">
          <p class="small">Presented By</p>
          <img src="${ _marker.link.logo }" alt="${_marker.name}"/>
        </div>
      `:`` }
      </div>`;
    } else {
      templateBase = `<div class="desktop-container bg-white">
        <div class="item-details event-venue">
        ${ _marker.venue_id !== null ? '<div class="expand-chevron"><a href="#">v</a></div>' : '' }
          <div class="close-x"><a href="#" data-venue-id="${_marker.id}">&times;</a></div>
          <div class="mobile-wrapper">
            ${ _marker.image !== '' && _marker.image !== undefined  ? `<div class="container-fluid detail-map" style="background: url(${_marker.image}) no-repeat center center;" ></div>`:`<div class="cs-m-spacer"></div><div class="cs-m-spacer"></div>` }
          </div>
          <div class="mobile-wrapper left-align">
            ${ _marker.food !== null ? '<span class="food-category">Featured Food</span>' : '' }
            <h6>${_marker.name}</h6>
            ${ _marker.description !== null || _marker.description !== '' ? `<p class="small">${_marker.description}</p><p class="mobile-description">${_marker.shortdescription}</p>` : '' }
            <div class="details-and-directions">
              ${$(window).width() < 768 ? `<div class="link btn btn-primary directions-button">Directions</div>` : ``}
              ${$(window).width() < 768 ? `<span class="break"></span>` : ``}
              ${ _marker.link['url:'] !== '' && _marker.link['url:'] !== undefined ? `<a href="${_marker.link['url:']}" class="details-button link btn btn-primary">See Details</a>`:``}
            </div>
          </div>
        </div>
        ${ _marker.link.logo !== '' && _marker.link.logo !== undefined ? `
        <div class="sponsor-image">
          <p class="small">Presented By</p>
          <img src="${ _marker.link.logo }" alt="${_marker.name}"/>
        </div>
      `:`` }
      </div>`;
  }


    let templateEvents = `
      <div class="content-wrapper">
      ${eventsRelated.map((_elm) => `
      ${_elm[0] === actualHour &&
        happeningNowIsRendered ? `
          <h4 class="group-title-event">
            On Now
          </h4>`: ``}

          ${_elm[0] !== actualHour &&
            upComingIsRendered ? `
          <h4 class="group-title-event">
            Up next
          </h4>
          `: ``}
          ${this.renderEvents(_elm[1])}
        `).join('')}
      </div>
    `;

    if (!_.isEmpty(eventsRelated)) {
      templateBase += templateEvents;
    } else {
      templateBase += this.renderNoFoundEvents();
    }

    if (!_.isEmpty(eventsRelated)) {
      templateBase += `
        <a class="see-all-events" href="/stampede/schedule" target="_blank">
        SEE FULL SCHEDULE
        </a>
      `;
    }

    wrapper.html(templateBase).promise().done(() => {
      if (!_.isEmpty(eventsRelated)) {
        wrapper.addClass('show-events');
      } else {
        wrapper.removeClass('show-events');
      }
      wrapper.removeClass('type-event type-food hide-info').addClass('show-info');

      let closeModal = $('.close-x a'),
        _self = this,
        bigIcon = '';

      closeModal.on('click', () => {
        if (_self.pinSelected.hasOwnProperty('id') ) {
          bigIcon = _self.pinSelected.icon.replace('MapPins_70x75', 'Map%20Pin%20Icons_PNG_60X65');
          _self.pinSelected.setIcon(bigIcon);
        }

        if (wrapper.hasClass('show-info')) {
          wrapper.removeClass('show-info show-events').addClass('hide-info');
          wrapper.empty();
        }
      });

      let expandModal = $('.expand-chevron a');

      expandModal.on('click', () => {
        wrapper.toggleClass('content-open');
      });


      let closeModalEvents = $('.icon-close');
      closeModalEvents.on('click', () => {
        if (_self.pinSelected.hasOwnProperty('id') ) {
          bigIcon = _self.pinSelected.icon.replace('MapPins_70x75', 'Map%20Pin%20Icons_PNG_60X65');
          _self.pinSelected.setIcon(bigIcon);
        }

        if (wrapper.hasClass('show-info')) {
          wrapper.removeClass('show-info show-events').addClass('hide-info');
          wrapper.empty();
        }
      });

      $('body').trigger('event-loaded');
    });

    let _self = this;

    $('.directions-button').on('click', function() {
      _self.getDirections(_marker);
    })
  }

 /**
  * Create google map marker
  * @param  { Object } - _marker. Venue details
  */
  drawMarker(_marker, isBigPin = 0) {
    let config = {
      map: this.map,
      position: {
        lat: _marker.bounds['lat'],
        lng: _marker.bounds['lng']
      },
      animation: google.maps.Animation.DROP,
      icon: _marker.icon,
      title: _marker.name,
      category: _marker.category,
      id: _marker.id,
      food: _marker.food,
      venue_id: _marker.venue_id,
      link: {
        url: _marker.link['url:']
      }
    },
    _self = this,
    pin = new google.maps.Marker(config),
    bigIcon = '';

    //  pre-defined pin
    if (isBigPin > 0) {
      _self.pinSelected = pin;
      _self.pinUnSelected = _self.pinSelected;
      bigIcon = pin.icon.replace('Map%20Pin%20Icons_PNG_60X65','MapPins_70x75');
      pin.setIcon(bigIcon);
    }

    google.maps.event.addListener(pin, 'click', function() {
      _self.map.panTo(this.getPosition());
      // _self.map.setZoom(zoom); Zoom to the pin.

      if (!_marker.food && _marker.id) {
        _self.renderModalEvents(_marker);
      } else {
        _self.renderModalInfo(_marker);
      }

      _self.pinUnSelected = _self.pinSelected;
      _self.pinSelected = this;

      if (_self.pinUnSelected.hasOwnProperty('id') && _self.pinSelected.getPosition().lat !== _self.pinUnSelected.getPosition().lat ) {
        bigIcon = _self.pinUnSelected.icon.replace('MapPins_70x75', 'Map%20Pin%20Icons_PNG_60X65');
        _self.pinUnSelected.setIcon(bigIcon);
      }

      bigIcon = this.icon.replace('Map%20Pin%20Icons_PNG_60X65','MapPins_70x75');
      this.setIcon(bigIcon);
    });

    _self.setMarkers(pin);
    return pin;
  }

 /**
  * Generate Markers using Clusterer tool
  * @param  { Array } - _venues. List
  * @param  { String } - _clusterIconPath. Icon
  */
  clusterMarkers(_venues, _clusterIconPath) {
    let _self = this,
      markers = _venues.map(function(_venue) {
        return _self.drawMarkerCluster(_venue);
    });
    this.MarkerCluster = new MarkerClusterer(_self.map, markers,{
      minimumClusterSize: 1,
      maxZoom: 16,
      styles: [{
        url: _clusterIconPath,
        width: 54,
        height: 53,
        textColor: '#fff',
        textSize: 16,
      }]
    });
    this.MarkerCluster.setImageExtension('svg');
  }


 /**
  * Create google map marker Cluster
  * @param  { Object } - _marker. Venue details
  * @return google.maps.Marker
  */
  drawMarkerCluster(_marker) {
    let config = {
      position: {
        lat: _marker.bounds['lat'],
        lng: _marker.bounds['lng']
      },
      animation: google.maps.Animation.DROP,
      icon: _marker.icon,
      title: _marker.name,
      category: _marker.category,
      id: _marker.id,
      food: _marker.food,
      venue_id: _marker.venue_id,
      link: {
        url: _marker.link['url:']
      }
    },
    _self = this,
    pin = new google.maps.Marker(config),
    bigIcon = '';

    google.maps.event.addListener(pin, 'click', function() {
      _self.map.panTo(this.getPosition());

      if (_self.map.getZoom() <= 16) {
        _self.map.setZoom(zoom);
      }

      if (!_marker.food && _marker.id) {
        _self.renderModalEvents(_marker);
      } else {
        _self.renderModalInfo(_marker);
      }

      _self.pinUnSelected = _self.pinSelected;
      _self.pinSelected = this;

      if (_self.pinUnSelected.hasOwnProperty('id') && _self.pinSelected.getPosition().lat !== _self.pinUnSelected.getPosition().lat ) {
        bigIcon = _self.pinUnSelected.icon.replace('MapPins_70x75', 'Map%20Pin%20Icons_PNG_60X65');
        _self.pinUnSelected.setIcon(bigIcon);
      }

      bigIcon = this.icon.replace('Map%20Pin%20Icons_PNG_60X65','MapPins_70x75');
      this.setIcon(bigIcon);

    });
    _self.setMarkers(pin);
    return pin;
  }



 /**
  * Create my location custom button
  * @param  { Google Maps } - _map. Instance
  */
  drawLocationButton(_map) {
    let controlDiv = document.createElement('div'),
      locationButton = document.createElement('button'),
      insideIcon = document.createElement('i'),
      animationInterval = 0;

    $(locationButton).addClass('user-location').attr('title', 'Your Location');
    $(controlDiv).append(locationButton);

    $(insideIcon).addClass('location-icon');
    $(locationButton).append(insideIcon);

    google.maps.event.addListener(_map, 'center_changed', () => {
      insideIcon.style['background-position'] = '0 0';
      clearInterval(animationInterval);
    });

    locationButton.addEventListener('click', () => {
        let geolocation = window.navigator.geolocation,
            imgX = '0';

        animationInterval = setInterval(function () {
            imgX = imgX === '-18' ? '0' : '-18';
            insideIcon.style['background-position'] = imgX+'px 0';
        }, 500);

        if (geolocation) {
          _map.panTo(this.currentLocation);
          geolocation.getCurrentPosition(function(position) {
            var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            clearInterval(animationInterval);
            _map.setCenter(latlng);
            insideIcon.style['background-position'] = '-144px 0';
          });
        } else {
          insideIcon.style['background-position'] = '-18px 0';
        }
    });

    locationButton.index = 1;
    _map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton);
  }

 /**
  * Create location marker
  * @param  { Object } - _geolocationInfo. User location info
  */
  displayUserLocation(_geolocationInfo) {
    try {
      let _self = this;
      _geolocationInfo.getCurrentPosition((_position) => {
        _self.currentLocation = {
          lat: _position.coords.latitude,
          lng: _position.coords.longitude
        };

        _self.map.setCenter(_self.currentLocation);

        const markerOpts = {
          'clickable': false,
          'cursor': 'pointer',
          'draggable': false,
          'icon': {
              'url': 'https://chadkillingsworth.github.io/geolocation-marker/images/gpsloc.png',
              'size': new google.maps.Size(34, 34),
              'scaledSize': new google.maps.Size(17, 17),
              'origin': new google.maps.Point(0, 0),
              'anchor': new google.maps.Point(8, 8)
          },
          'flat': true,
          'optimized': false,
          'position': new google.maps.LatLng(_self.currentLocation.lat, _self.currentLocation.lng),
          'map': _self.map,
          'animation': google.maps.Animation.DROP,
          'title': 'Current location',
          'zIndex': 3
        };

        const circleOpts = {
          'clickable': false,
          'strokeColor': '#6198f2',
          'strokeOpacity': .4,
          'fillColor': '#c5daec',
          'fillOpacity': .4,
          'strokeWeight': 1,
          'zIndex': 3,
          'center': new google.maps.LatLng(_self.currentLocation.lat, _self.currentLocation.lng),
          'radius': 30
        };

        const MyLocationMarker = new GeolocationMarker();

        MyLocationMarker.setMarkerOptions(markerOpts);
        MyLocationMarker.setCircleOptions(circleOpts);

        google.maps.event.addListenerOnce(MyLocationMarker, 'position_changed', function() {
          _self.map.setCenter(this.getPosition());
          _self.map.fitBounds(this.getBounds());
        });

        google.maps.event.addListener(MyLocationMarker, 'geolocation_error', function(e) {
          alert('There was an error obtaining your position. Message: ' + e.message);
        });

        MyLocationMarker.setMap(_self.map);
        _self.drawLocationButton(_self.map);
      });

    } catch (_error) {
      console.error(_error); // eslint-disable-line
    }
  }

  getDirections(marker) {
    let _self = this;
    //Clicked Marker Location
    let markerLocation = marker.bounds;
    //Users Location
    let currentLocation = _self.currentLocation;

    function setMapOnAll(showOrHide) {
      for (var i = 0; i < _self.markers.length; i++) {
        if (_self.markers[i].category !== marker.category) {
          _self.markers[i].setVisible(showOrHide);
        }
      }
    }

    function resetDirections() {
      //Set all markers visibility to true
      setMapOnAll(true);
      //remove reset-button
      $('.reset-directions').remove();
      //Set marker clusters min cluster size back to original (1 in this case)
      _self.MarkerCluster.minClusterSize_ = 1;
    }

    if (Object.entries(currentLocation).length !== 0) {
      //Get middle ground for two markers
      let middleGround = {
        lat: ((markerLocation.lat + currentLocation.lat) / 2.0),
        lng: ((currentLocation.lng + markerLocation.lng) / 2.0)
      }
      //Get middle bounds for two markers
      let middleBounds = new google.maps.LatLngBounds();
      middleBounds.extend(markerLocation);
      middleBounds.extend(currentLocation);
      //Set center & bounds
      _self.map.setCenter(middleGround);
      _self.map.fitBounds(middleBounds);
      //Remove Markers
      setMapOnAll(false);

      this.MarkerCluster.minClusterSize_ = 50;

      //Add reset directions button
      $('#map-details').append('<div class="link btn btn-primary reset-directions">Reset Directions</div>');
      $('.reset-directions').on('click', function() {
        resetDirections();
      });
    } else {
      alert('Error locating your position');
    }
  }

  getTodayEvents() {
    let _self = this;

    $.ajax({
      type: 'GET',
      url: `${window.location.origin}/schedule-view/${moment().format('YYYY-M-D')}/all/all`,
      success: function(data) {
        _self.getData(data);
      },
      error: function() {
        console.error('Error fetching event day data'); // eslint-disable-line
      }
    });
  }

  renderFavoriteEvents(_elements) {
    return `
    <div class="${ prefix }-m-happening items" style="display: flex;">
    ${ _elements.reverse().map((event) =>
      `<div class="group-action">
        <a href="#" ${ event.modalCard ? `data-toggle="modal" data-target="#modal-card-${ event.modalCard.id }"` : `` } class="item cs-c-happening-card">
          <div class="content">
            <div class="copy">
              <div class="middle-section">
                <h5>${ event.artist }</h5>
              </div>
              <div class="bottom-section">
                <span class="venue-term">${ event.title }</span>
                  <h6>${ event.startHour } - ${ event.endHour }</h6>
              </div>
            </div>
          </div>
        </a>
        <div class="set-icons">
          <a target="_blank" href="${ encodeURI(`mailto:?subject=${event.artist}&body=${event.caption}\n${ event.modalCard.datetime }\n${ event.modalCard.moreTimes }`) }" class="icon">
            <span class="icon-email"></span>
            EMAIL EVENT
          </a>
          <a class="icon icon-download" data-event="${this.stringifyObject(event)}">
            <span class="icon-download-logo"></span>
            ADD TO CALENDAR
          </a>
          <a class="icon" target="_blank" href="/stampede/maps/park?venue_id=${event.venue_id}">
            <span class="icon-map"></span>
            MAP VIEW
          </a>
        </div>
        <a href="#" class="add-favorites isModal" aria-label="Add to Favorites" data-id="${event.collectionId}" data-event="${this.stringifyObject(event)}" }'></a>
      </div>`).join('')}
    </div>`;
  }

  renderNoSavedEvents() {
    return `
    <div class="${ prefix }-no-events">
      <div class="no-events-container">
        <span class="red-start"></span>
        <h5>YOU HAVE NO SAVED EVENTS</h5>
        <p>You can save an event by selecting the star on the Stampede Schedule</p>
        <a href="/stampede/schedule" class="link btn btn-primary">VIEW SCHEDULE</a>
      </div>
    </div>
    `;
  }

  renderNoFoundEvents() {
    // New requirement: we dont nedd to show any message if there is no an event.
    return ``;
    // return `
    // <div class="${ prefix }-no-events">
    //   <div class="no-events-container"></div>
    // </div>
    // `;
  }

  setModalEventFavorites() {
    let _favorites = this._favoritesClass.getItemsSaved();
    this.renderGroups(_favorites);
    $('body').trigger('event-loaded');
  }

  groupEventsByDay(_events) {
    let groups = Array();

    for (var i = 0; i < _events.length; i++) {
      let groupName = _events[i].day;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(_events[i].event);

      for (let key in groups) {
        groups[key];
      }
    }

    let parseObjectToArray = Object.keys(groups).map(function(e) {
      return [e, groups[e]];
    });

    return parseObjectToArray;
  }

  renderGroups(_events) {
    let template = '';
    let collectionBydays = [];

    $.each(_events, function(_i, _elm) {
      collectionBydays.push({ day: _elm.node.field_event_date3 !== '' ? moment(_elm.node.field_event_date3, 'MMMM DD').format('ll') : '', event: _elm });
    });

    collectionBydays = this.groupEventsByDay(collectionBydays);

    template += `
          <div class="content-wrapper">
          ${collectionBydays.reverse().map((_elm) => `
            <h4 class="group-title-event">${moment(_elm[0], 'MMMM DD').format('dddd, MMMM DD')}</h4>
            ${this.renderFavoriteEvents(_elm[1])}
          `).join('')}
          </div>
        `;

    if (typeof collectionBydays[0] !== 'undefined' && collectionBydays[0] !== null) {
      this.cache.modalFavorites.removeClass('no-events-found');
      this.cache.modalFavorites.html(template);
    } else {
      this.cache.modalFavorites.addClass('no-events-found');
      this.cache.modalFavorites.html(this.renderNoSavedEvents());
    }
  }

  init() {

    if( $(`.${ prefix }-container-map`).length ) {

      let config = {
        'center': {
        'lat': latitude,
        'lng': longitude
        },
        'zoom': zoom,
        'streetViewControl': false,
        'mapTypeControl': false
      },
      _self = this;

      //Adjust required to full width map
      if(this.cache.body.length === 1) {
        $('html').addClass(`${prefix}-p-maps`)
      } else {
        $('html').removeClass(`${prefix}-p-maps`);
      }

      $('#modal-overlay').css('z-index','1');

      $.urlParam = function(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if(Array.isArray(results))
        {
          if (results[1] !== undefined) {
            return results[1] || 0;
          }
        }
        return 0;
      }

      var venueShowModal = $.urlParam('venue_id');

      google.maps.event.addDomListener(window, 'load', function () {
        _self.map = new google.maps.Map(_self.cache.container, config);
        _self.setMapType();
        _self.mapListeners();

        //  set current user location
        if (isMobile() && (venueShowModal === null || venueShowModal === undefined || venueShowModal === 0) ) {
          const geolocation = window.navigator.geolocation;
          if (geolocation) {
            _self.displayUserLocation(geolocation);
          }
        }

        if (typeof venues_data !== 'undefined') {
          if (venues_data.venues.length > 0) {

            if(venueShowModal !== null && venueShowModal !== undefined && venueShowModal !== 0) {

              for(var venue of venues_data.venues) {

                if(venue.venue_id === parseInt(venueShowModal)) {
                  if (!venue.food && venue.venue_id) {
                    _self.renderModalEvents(venue);
                  } else {
                    _self.renderModalInfo(venue);
                  }
                  var bigPin = _self.drawMarker(venue, 1);
                  _self.map.panTo(bigPin.getPosition());

                }else {
                  _self.drawMarker(venue);
                }
              }
            } else {
              _self.clusterMarkers(venues_data.venues, '/sites/all/themes/calgarystampede2017/dist2017/images/svg/icon-clusterer.svg');
            }
          }
        } else {
          const callVenues = _self.getAsyncMarkers();
          callVenues.then(function(_results) {
            // Create marks in your map
            if (_results.venues.length > 0) {

              if(venueShowModal !== null && venueShowModal !== undefined && venueShowModal !== 0) {


                for(var venue of _results.venues) {
                  if(venue.venue_id === parseInt(venueShowModal)) {
                    _self.renderModalInfo(venue);
                    var bigPin = _self.drawMarker(venue, 1);
                    _self.map.panTo(bigPin.getPosition());

                  }else {
                    _self.drawMarker(venue);
                  }
                }
              } else {
                _self.clusterMarkers(_results.venues, '/images/svg/icon-clusterer.svg');
              }
            }
          }, function() {
          });
        }
      });

      _self.cache.header.find('li a').click(function(event) {
        if(event.target.id === 'filter-nf') {
          for(let marker of _self.getMarkers() ) {
            if(marker.food !== null && marker.food !== undefined) {
              marker.setVisible(true);
            }else{
              marker.setVisible(false);
            }
          }
        }

        if(event.target.id === 'filter-pm') {
          for(let marker of _self.getMarkers() ) {
            if(marker.category !== null && marker.category !== undefined) {
              marker.setVisible(true);
            }else{
              marker.setVisible(false);
            }
          }
        }
      });

      _self.getTodayEvents();

      this.cache.favoritesBtn.on('click', function(ev) {
        let $btn = $(this),
            isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;

          if (isExpanded) {
            _self.setModalEventFavorites();
          }

          if(isExpanded) {
            _self.cache.mobileFavoritesBtnWrapper.addClass('display-triangle');
          } else {
            _self.cache.mobileFavoritesBtnWrapper.removeClass('display-triangle');
          }

          $btn.attr('aria-expanded', isExpanded);

          if (_self.cache.modalFavorites.hasClass('modal-open')) {
            _self.cache.modalFavorites.removeClass('modal-open').addClass('modal-close');
          } else {
            _self.cache.modalFavorites.removeClass('modal-close').addClass('modal-open');
          }
          ev.preventDefault();
      });

      $('body').on('event-loaded', function() {

        $.extend(_self.cache, {'favorites': $(`.${ prefix }-c-happening-card .add-favorites, .${ prefix }-m-happening .add-favorites`)});

          //  Pre-select favorites from localStorage
          let isEventSaved = false;
          $.each(_self.cache.favorites, function(_i, _elm) {
            isEventSaved = _self._favoritesClass.itemExists($(_elm).data('id'));
            if (isEventSaved) {
              $(_elm).addClass('favorite');
              _self.cache.favorites.attr('has-events', 'true');
            } else {
              $(_elm).removeClass('favorite');
            }
          });

          _self.cache.favorites.unbind().on('click', function(ev) {
            let eventCard = $(this);

            $.each(_self.cache.favorites, function(_i, _e) {
              let element = $(_e);

              if (parseInt(element.attr('data-id')) === parseInt(eventCard.attr('data-id'))) {
                element.toggleClass('favorite');
              }
            });

            _self._favoritesClass.updateItems(_self.parseStringifyObject(eventCard.data('event')));

            let favorites = _self.parseStringifyObject(window.localStorage.getItem('favorites'));

            if (eventCard.hasClass('isModal')) {
              _self.setModalEventFavorites();
            }

            if (favorites !== null && favorites.length !== 0) {
              _self.cache.favoritesBtn.attr('has-events', 'true');
            } else {
              _self.cache.favoritesBtn.attr('has-events', 'false');
            }

            ev.preventDefault();
          });

          $('.icon-download').unbind().on('click', function(event) {
            let found =  _self.parseStringifyObject($(this).attr('data-event'));

            if (typeof found !== 'undefined') {
              let cal = icsGen(),
                filename = found.artist,
                url = window.location.origin;

              cal.addEvent(found.artist,
                found.caption,
                found.title,
                `7/${found.node.field_event_date3_1}/2019 ${found.startHour}`,
                `7/${found.node.field_event_date3_1}/2019 ${found.endHour}`, null);
              cal.download(filename, 'ics',`${url}/sites/all/themes/calgarystampede2017/icalendar.php`);
            }

            event.preventDefault();
          });
      });
    }
  }
}

export default MapConfig;