export default (map) => {
  const cache = {
    'buttons': {
      'categoriesBtn': $('#all-categories'),
      'venuesBtn': $('#all-venues'),
      'foodsBtn': $('#all-foods'),
    },
    'modals': {
      'categories': $('#modal-categories'),
      'venues': $('#modal-venues'),
      'foods': $('#modal-foods')
    },
    'overlay': $('#modal-overlay'),
    'mapContainer': $('.cs-container-map'),
    'filtersContainer': $('.modals-set')
  };

  let modal = {},
  allCategories = cache.modals.categories.find('ul .event-item'),
  venuesCategories = cache.modals.venues.find('ul .event-item'),
  foodCategories = cache.modals.foods.find('ul .event-item');

  let globalSelectedCatagories = [];

 /**
  * Set the modal type
  * @param {Number} - _modalType. 1= Events modal, 2= Food Modal
  */
  const setModal = (_modalType) => {
    switch(_modalType) {
      //events
      case 'venues':
        modal = cache.modals.venues;
      break;
      //categories
      case 'categories':
        modal = cache.modals.categories;
      break;
      //foods
      case 'foods':
        modal = cache.modals.foods;
      break;
      default:
      break;
    }
    //Animate modal
    animateModal();
  };

 /**
  * Show/Hide modal selected by the user
  */
  const animateModal = () => {
    if (modal.hasClass('modal-open') ) {
      modal.removeClass('modal-open').addClass('modal-close');
      modal.fadeOut('fast');
    } else {
      modal.removeClass('modal-close').addClass('modal-open');
      modal.fadeIn('fast');
    }
  };

  /**
   * Close filters configuration and set default state
   */
  const closeFilters = () => {
    cache.buttons.categoriesBtn.html('FILTER  <span class="icon-list"></span>');
    cache.buttons.venuesBtn.html('VENUES <span class="icon-list"></span>');
    cache.buttons.foodsBtn.html('NEW FOODS <span class="icon-list"></span>');
    cache.modals.venues.removeClass('modal-open').addClass('modal-close');
    cache.modals.categories.removeClass('modal-open').addClass('modal-close');
    cache.modals.foods.removeClass('modal-open').addClass('modal-close');
    cache.buttons.categoriesBtn.attr('aria-expanded', false);
    cache.buttons.venuesBtn.attr('aria-expanded', false);
    cache.buttons.foodsBtn.attr('aria-expanded', false);
  }

 /**
  * Initialize event listeners
  */
  const initListeners = () => {

    // buttons
    cache.buttons.categoriesBtn.on('click', function (ev) {
      let $btn = $(this),
          isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;

      if (cache.buttons.venuesBtn.attr('aria-expanded') === 'true') {
        cache.buttons.venuesBtn.html('VENUES <span class="icon-list"></span>');
        closeFilters();
      }
      if (cache.buttons.foodsBtn.attr('aria-expanded') === 'true') {
        cache.buttons.foodsBtn.html('NEW FOODS <span class="icon-list"></span>');
        closeFilters();
      }

      setModal($btn.data('type'));
      $btn.attr('aria-expanded', isExpanded);

      if (isExpanded) {
        $btn.html('CLOSE <span class="icon-list"></span>');
      } else {
        $btn.html('FILTER  <span class="icon-list"></span>');
      }
      ev.preventDefault();
    });

    cache.buttons.venuesBtn.on('click', function (ev) {
      let $btn = $(this),
          isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;

      if (cache.buttons.categoriesBtn.attr('aria-expanded') === 'true') {
        cache.buttons.categoriesBtn.html('FILTER  <span class="icon-list"></span>');
        closeFilters();
      }
      if (cache.buttons.foodsBtn.attr('aria-expanded') === 'true') {
        cache.buttons.foodsBtn.html('NEW FOODS <span class="icon-list"></span>');
        closeFilters();
      }

      setModal($btn.data('type'));
      $btn.attr('aria-expanded', isExpanded);

      if (isExpanded) {
        $btn.html('CLOSE LIST <span class="icon-list"></span>');
      } else {
        $btn.html('VENUES <span class="icon-list"></span>');
      }
      ev.preventDefault();
    });

    cache.buttons.foodsBtn.on('click', function (ev) {
      let $btn = $(this),
          isExpanded = ($btn.attr('aria-expanded') === 'true') ? false : true;

      if (cache.buttons.categoriesBtn.attr('aria-expanded') === 'true') {
        cache.buttons.categoriesBtn.html('FILTER  <span class="icon-list"></span>');
        closeFilters();
      }

      if (cache.buttons.venuesBtn.attr('aria-expanded') === 'true') {
        cache.buttons.venuesBtn.html('LIST VIEW <span class="icon-list"></span>');
        closeFilters();
      }

      $btn.attr('aria-expanded', isExpanded);

      setModal($btn.data('type'));

      if (isExpanded) {
        $btn.html('CLOSE LIST <span class="icon-list"></span>');
      } else {
        $btn.html('NEW FOODS <span class="icon-list"></span>');
      }
      ev.preventDefault();
    });

    //Food items
    cache.modals.foods.find('ul.event-sub-list').on('click', 'a', function (ev) {
      let $btn = $(this),
          idVenue = parseInt($btn.data('id'));

      if (idVenue) {
        let marker = map.markers.find(function(_pin) {
          return _pin.id === idVenue;
        });
        
        
        google.maps.event.trigger(marker, 'click');
        
        if (!globalSelectedCatagories.includes(...marker.category)) {
          let categoryParent = $btn.parent().parent().prev();
          categoryParent.addClass('item-active');
          globalSelectedCatagories.push(...marker.category);
        }  
        
      }

      if (cache.buttons.categoriesBtn.attr('aria-expanded') === 'true') {
        cache.buttons.categoriesBtn.html('FILTER  <span class="icon-list"></span>');
      }

      if (cache.buttons.venuesBtn.attr('aria-expanded') === 'true') {
        cache.buttons.venuesBtn.html('LIST VIEW <span class="icon-list"></span>');
      }

      if (cache.buttons.foodsBtn.attr('aria-expanded') === 'true') {
        cache.buttons.foodsBtn.html('NEW FOODS <span class="icon-list"></span>');
      }

      closeFilters();
      ev.preventDefault();
    });

    //Modals
    cache.modals.venues.find('ul').on('click', 'a', function (ev) {
      let $btn = $(this),
      $allCategories = $(venuesCategories),
      idCategory = parseInt($btn.data('id')),
      categoriesSelected = [],
      currentZoom = map.map.getZoom();

      if(currentZoom <= 16) {
        map.map.setZoom(18);
      }

      if (idCategory || idCategory !== 0) {
        $(venuesCategories[0]).removeClass('item-active');

        if (!globalSelectedCatagories.includes(idCategory)) {
          $.each($allCategories, function(_i, _e) {
            let $cat = $(_e);
            let catId = $cat.data('id');
              if (parseInt(catId) === idCategory && !$btn.hasClass('item-active')) {
                categoriesSelected.push(idCategory);
                if (!globalSelectedCatagories.includes(idCategory)) {
                  globalSelectedCatagories.push(idCategory);
                }
              } else if (parseInt(catId) !== idCategory && $cat.hasClass('item-active')) {
                categoriesSelected.push(parseInt(catId));
                if (!globalSelectedCatagories.includes(catId)) {
                  globalSelectedCatagories.push(catId);
                }
              }
            });
        } else {
          globalSelectedCatagories = globalSelectedCatagories.filter(e => e !== idCategory);
        }

        if (_.isEmpty(globalSelectedCatagories)) {
          $(venuesCategories[0]).addClass('item-active');
        }

        $btn.toggleClass('item-active');
      } else {
        globalSelectedCatagories = [];
        $.each($allCategories, function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        $.each($(allCategories), function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        $.each($(foodCategories), function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        
        $(venuesCategories[0]).addClass('item-active');
        $(allCategories[0]).addClass('item-active');
        $(foodCategories[0]).addClass('item-active');
      }
      
      if (_.isEmpty(globalSelectedCatagories)) {
        $.each(map.markers, function(_i, _marker) {
          _marker.setVisible(true);
        });
      } else {
        $.each(map.markers, function(_i, _marker) {
            let isSelected = _.find(_marker.category, function(catId) {
              let checkCategory = _.find(globalSelectedCatagories, function(catSelected) {
                if (catSelected < 100) {
                  if (catSelected === _marker.id) {
                    return catSelected;
                  } 
                } else {
                  if (catSelected === catId) {
                    return catSelected;
                  }
                }
              });
  
              if (checkCategory) {
                return catId;
              }
            });
        
          if (isSelected) {
            _marker.setVisible(true);
          } else {
            _marker.setVisible(false);
          }
        });
      }

      if (cache.buttons.foodsBtn.attr('aria-expanded') === 'true') {
        cache.buttons.foodsBtn.html('NEW FOODS <span class="icon-list"></span>');
      }
      ev.preventDefault();
    });

    cache.modals.categories.find('ul').on('click', 'a', function (ev) {
      let $btn = $(this),
      $allCategories = $(allCategories),
      idCategory = parseInt($btn.data('id')),
      categoriesSelected = [],
      currentZoom = map.map.getZoom();

      if(currentZoom <= 16) {
        map.map.setZoom(18);
      }
      if (idCategory || idCategory !== 0) {
        $(allCategories[0]).removeClass('item-active');

        if (!globalSelectedCatagories.includes(idCategory)) {
          $.each($allCategories, function(_i, _e) {
            let $cat = $(_e);
            let catId = $cat.data('id');
              if (parseInt(catId) === idCategory && !$btn.hasClass('item-active')) {
                categoriesSelected.push(idCategory);
                if (!globalSelectedCatagories.includes(idCategory)) {
                  globalSelectedCatagories.push(idCategory);
                }
              } else if (parseInt(catId) !== idCategory &&
              $cat.hasClass('item-active')) {
                categoriesSelected.push(parseInt(catId));
                if (!globalSelectedCatagories.includes(catId)) {
                  globalSelectedCatagories.push(catId);
                }
              }
            });
        } else {
          globalSelectedCatagories = globalSelectedCatagories.filter(e => e !== idCategory);
        }

        if (_.isEmpty(globalSelectedCatagories)) {
          $(allCategories[0]).addClass('item-active');
        }

        $btn.toggleClass('item-active');
      } else {
        globalSelectedCatagories = [];
        $.each($allCategories, function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        $.each($(foodCategories), function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        $.each($(venuesCategories), function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });

        $(allCategories[0]).addClass('item-active');
        $(foodCategories[0]).addClass('item-active');
        $(venuesCategories[0]).addClass('item-active');
      }

      if (_.isEmpty(globalSelectedCatagories)) {
        $.each(map.markers, function(_i, _marker) {
          _marker.setVisible(true);
        });
      } else {
        $.each(map.markers, function(_i, _marker) {
          let isSelected = _.find(_marker.category, function(catId) {
            let checkCategory = _.find(globalSelectedCatagories, function(catSelected) {
              if (catSelected < 100) {
                if (catSelected === _marker.id) {
                  return catSelected;
                }
              } else {
                if (catSelected === catId) {
                  return catSelected;
                }
              }
            });

            if (checkCategory) {
              return catId;
            }
          });

          if (isSelected) {
            _marker.setVisible(true);
          } else {
            _marker.setVisible(false);
          }
        });
      }
      ev.preventDefault();
    });

    cache.modals.foods.find('ul').on('click', 'a', function (ev) {
      let $btn = $(this),
      $allCategories = $(foodCategories),
      idCategory = parseInt($btn.data('id')),
      categoriesSelected = [],
      currentZoom = map.map.getZoom();

      globalSelectedCatagories = _.uniq(globalSelectedCatagories);

      if(currentZoom <= 16) {
        map.map.setZoom(18);
      }

      if (idCategory || idCategory !== 0) {
        $(foodCategories[0]).removeClass('item-active');

        if (!globalSelectedCatagories.includes(idCategory)) {
          $.each($allCategories, function(_i, _e) {
            let $cat = $(_e);
            let catId = $cat.data('id');
              if (parseInt(catId) === idCategory && !$btn.hasClass('item-active')) {
                categoriesSelected.push(idCategory);
                if (!globalSelectedCatagories.includes(idCategory)) {
                  globalSelectedCatagories.push(idCategory);
                }
              } else if (parseInt(catId) !== idCategory &&
              $cat.hasClass('item-active')) {
                categoriesSelected.push(parseInt(catId));
                if (!globalSelectedCatagories.includes(catId)) {
                  globalSelectedCatagories.push(catId);
                }
              }
            });
        } else {
          globalSelectedCatagories = globalSelectedCatagories.filter(e => e !== idCategory);
        }

        if (_.isEmpty(globalSelectedCatagories)) {
          $(foodCategories[0]).addClass('item-active');
        }

        $btn.toggleClass('item-active');
      } else {
        globalSelectedCatagories = [];
        $.each($allCategories, function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        $.each($(allCategories), function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });
        $.each($(venuesCategories), function(_i, _e) {
          let $cat = $(_e);
          $cat.removeClass('item-active');
        });

        $(allCategories[0]).addClass('item-active');
        $(foodCategories[0]).addClass('item-active');
        $(venuesCategories[0]).addClass('item-active');
      }

      if (_.isEmpty(globalSelectedCatagories)) {
        $.each(map.markers, function(_i, _marker) {
          _marker.setVisible(true);
        });
      } else {
        $.each(map.markers, function(_i, _marker) {
          let isSelected = _.find(_marker.category, function(catId) {
            let checkCategory = _.find(globalSelectedCatagories, function(catSelected) {
              if (catSelected < 100) {
                if (catSelected === _marker.id) {
                  return catSelected;
                }
              } else {
                if (catSelected === catId) {
                  return catSelected;
                }
              }
            });

            if (checkCategory) {
              return catId;
            }
          });

        if (isSelected) {
          _marker.setVisible(true);
        } else {
          _marker.setVisible(false);
        }
      });
    }

      ev.preventDefault();
    });

    // Close filters when user click on map.
    cache.mapContainer.on('click', function () {
      closeFilters();
    });

    cache.filtersContainer.on('click', function (ev) {
      if (ev.target !== this) {
        return;
      }
      closeFilters();
      ev.preventDefault();
    });
  };

  initListeners();
}
